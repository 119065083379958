// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type BaustellenListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheBaustellenFuerSelectQuery = {
  search?: string;
  kundenId?: string;
  page?: number;
  limit?: number;
  sort?: string;
  aktuelleBaustelle?: string;
};

export function useSucheBaustellenFuerSelect(): (
  query: SucheBaustellenFuerSelectQuery
) => Promise<BaustellenListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) =>
      (await getAPI()).baustellen.sucheBaustellenFuerSelect({ ...query }),
    [getAPI]
  );
}

import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaschinentransport,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useCreatePersonaleintrag,
  useCreateTaetigkeitsbericht,
} from "../../client/hooks";
import {
  FuhrparkeintragAnbauteil,
  Maschinentransport,
  Materialtransport,
  MaterialZwischenInfo,
} from "../../api";
import TaetigkeitsberichtFieldsContent from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContent";
import * as Yup from "yup";
import { ExpandContextProvider } from "../../expand";
import FuhrparkeintragEigenPersonalItem from "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragEigenPersonalItem";
import DeleteIcon from "@mui/icons-material/Delete";

interface ExtendedTaetigkeitsberichtDaten {
  mitarbeiterId: string;
  baustelleId: string;
  datum: string;
  arbeitszeitNetto: number;
  stundensatz: number;
  lohnkosten: number;
  bemerkung: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugId: string;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: FuhrparkeintragAnbauteil[];
  maschinentransporte: Maschinentransport[];
  materialanfuhr: Materialtransport[];
  materialabtransport: Materialtransport[];
  materialtransport: MaterialZwischenInfo[];
}

const validationSchema = Yup.object().shape({
  mitarbeiterId: yup.string().required(),
  baustelleId: yup.string().required(),
  datum: yup.string().required(),
  arbeitszeitNetto: yup.number().min(0).nullable(),
  bemerkung: yup.string(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeugId: yup.mixed().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteilId: yup.mixed().required(),
              anbauteilNutzungsdauer: yup.number().min(0).required(),
              anbauteilStundenPreis: yup.number().min(0).required(),
              anbauteilAnzahl: yup.number().min(1).required(),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
        maschinentransporte: Yup.array()
          .of(
            Yup.object().shape({
              transportiertesFahrzeugId: yup.mixed().required(),
              abholortBaustellenId: yup.mixed().required(),
              abstellortBaustellenId: yup.mixed().required(),
              lieferscheinNummer: yup.string(),
              bemerkung: yup.string().nullable(),
            })
          )
          .min(0, ""),
        materialanfuhr: Yup.array().of(
          Yup.object().shape({
            lieferscheinNummer: yup.string(),
            bemerkung: yup.string().nullable(),
          })
        ),
        materialabtransport: Yup.array().of(
          Yup.object().shape({
            lieferscheinNummer: yup.string(),
            bemerkung: yup.string().nullable(),
          })
        ),
      })
    )
    .min(0, ""),
});

enum Buchungstyp {
  AR = "AR",
  ER = "ER",
  PS = "PS",
  FP = "FP",
  MA = "MA",
  AB = "AB",
  DP = "DP",
  TR = "TR",
}

export default function ErstelleTaetigkeitsbericht() {
  const { t } = useTranslation("taetigkeitsbericht");
  const createTaetigkeitsbericht = useCreateTaetigkeitsbericht();
  const createPersonaleintrag = useCreatePersonaleintrag();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const createMaschinentransport = useCreateMaschinentransport();
  const createMaterialtransport = useCreateMaterialtransport();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();
  const navigate = useNavigate();

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
    }
  ) {
    try {
      const taetigkeitsbericht = await createTaetigkeitsbericht({
        baustelleId: values.baustelleId,
        datum: values.datum,
        taetigkeitsberichtTyp: "INTERN",
      });

      const personaleintrag = await createPersonaleintrag(
        taetigkeitsbericht.id,
        {
          mitarbeiterId: values.mitarbeiterId,
          baustelleId: values.baustelleId,
          datum: values.datum,
          arbeitszeitNetto: values.arbeitszeitNetto,
          stundensatz: values.stundensatz,
          bemerkung: values.bemerkung,
        }
      );

      // Create each fuhrparkeintrag
      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          const fuhrparkeintragData = {
            fahrzeugId: fuhrparkeintrag.fahrzeugId,
            fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
            fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
            fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
            bemerkung: fuhrparkeintrag.bemerkung,
          };
          return createFuhrparkeintrag(
            taetigkeitsbericht.id,
            fuhrparkeintragData
          );
        }
      );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);

      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      const fuhrparkeintragAnbauteilPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
            (fuhrparkeintragAnbauteil) => {
              const fuhrparkeintragAnbauteilData = {
                anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                anbauteilNutzungsdauer:
                  fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                anbauteilStundenPreis:
                  fuhrparkeintragAnbauteil.anbauteilStundenPreis,
              };
              return createFuhrparkeintragAnbauteil(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                fuhrparkeintragAnbauteilData
              );
            }
          );
        }
      );

      await Promise.all(fuhrparkeintragAnbauteilPromises);

      const maschinentransportePromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.maschinentransporte.map(
            (maschinentransport) => {
              const maschinentransportData = {
                baustelleId: taetigkeitsbericht.baustelleId,
                transportiertesFahrzeugId:
                  maschinentransport.transportiertesFahrzeugId,
                abholortBaustellenId: maschinentransport.abholortBaustellenId,
                abstellortBaustellenId:
                  maschinentransport.abstellortBaustellenId,
                fuhrTyp: Buchungstyp.TR,
                lieferscheinNummer: maschinentransport.lieferscheinNummer,
                bemerkung: maschinentransport.bemerkung,
              };
              return createMaschinentransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                maschinentransportData
              );
            }
          );
        }
      );

      await Promise.all(maschinentransportePromises);

      const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => {
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              quelleAbladestelleId: materialanfuhr.quelleAbladestelleId,
              zielBaustelleId: materialanfuhr.zielBaustelleId,
              materialId: materialanfuhr.materialId,
              buchungsTyp: Buchungstyp.MA,
              fuhrTyp: Buchungstyp.MA,
              lieferscheinNummer: materialanfuhr.lieferscheinNummer,
              bemerkung: materialanfuhr.bemerkung,
              einheit: materialanfuhr.einheit,
              anzahlFuhren: materialanfuhr.anzahlFuhren,
              kostenProEinheit: materialanfuhr.kostenProEinheit,
              menge: materialanfuhr.menge,
              meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
              buchungsArt: materialanfuhr.buchungsArt,
            };
            return createMaterialtransport(
              taetigkeitsbericht.id,
              currentFuhrparkeintragId,
              materialanfuhrData
            );
          });
        }
      );

      await Promise.all(materialanfuhrPromises);

      const materialabtransportPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialabtransport.map(
            (materialtransport) => {
              const materialanfuhrData = {
                baustelleId: taetigkeitsbericht.baustelleId,
                quelleAbladestelleId: materialtransport.quelleAbladestelleId,
                zielAbladestelleId: materialtransport.zielAbladestelleId,
                materialId: materialtransport.materialId,
                buchungsTyp: materialtransport.buchungsTyp,
                fuhrTyp: materialtransport.fuhrTyp,
                lieferscheinNummer: materialtransport.lieferscheinNummer,
                bemerkung: materialtransport.bemerkung,
                einheit: materialtransport.einheit,
                anzahlFuhren: materialtransport.anzahlFuhren,
                kostenProEinheit: materialtransport.kostenProEinheit,
                menge: materialtransport.menge,
                meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
                buchungsArt: materialtransport.buchungsArt,
                bsNr: materialtransport.bsNr,
                entsorgerId: materialtransport.entsorgerId,
                erzeugerId: materialtransport.erzeugerId,
              };
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialanfuhrData
              );
            }
          );
        }
      );

      await Promise.all(materialabtransportPromises);

      const materialtransportPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialtransport.map((materialtransport) => {
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              zielBaustelleId: materialtransport.zielBaustelleId,
              materialId: materialtransport.materialId,
              buchungsTyp: materialtransport.buchungsTyp,
              lieferscheinNummer: materialtransport.lieferscheinNummer,
              bemerkung: materialtransport.bemerkung,
              einheit: materialtransport.einheit,
              anzahlFuhren: materialtransport.anzahlFuhren,
              kostenProEinheit: materialtransport.kostenProEinheit,
              menge: materialtransport.menge,
              meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
              buchungsArt: materialtransport.buchungsArt,
            };
            return createMaterialtransportZwischenBaustellen(
              taetigkeitsbericht.id,
              currentFuhrparkeintragId,
              materialanfuhrData
            );
          });
        }
      );

      await Promise.all(materialtransportPromises);

      navigate(
        `/taetigkeitsberichte/${taetigkeitsbericht.id}?expandedindexInherited=${expandedIndex}`
      );
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten & {
        fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
      },
      formikHelpers: FormikHelpers<
        ExtendedTaetigkeitsberichtDaten & {
          fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
        }
      >
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      try {
        // Create Tätigkeitsbericht first
        const taetigkeitsbericht = await createTaetigkeitsbericht({
          baustelleId: values.baustelleId,
          datum: values.datum,
          taetigkeitsberichtTyp: "INTERN",
        });

        //Create Personaleintrag
        const personaleintrag = await createPersonaleintrag(
          taetigkeitsbericht.id,
          {
            mitarbeiterId: values.mitarbeiterId,
            baustelleId: values.baustelleId,
            datum: values.datum,
            arbeitszeitNetto: values.arbeitszeitNetto,
            stundensatz: values.stundensatz,
            bemerkung: values.bemerkung,
          }
        );

        // Create each fuhrparkeintrag
        const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
          (fuhrparkeintrag) => {
            const fuhrparkeintragData = {
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
            };
            return createFuhrparkeintrag(
              taetigkeitsbericht.id,
              fuhrparkeintragData
            );
          }
        );

        const fuhrparkeintragResults = await Promise.all(
          fuhrparkeintragPromises
        );

        const fuhrparkeintragIds = fuhrparkeintragResults.map(
          (result) => result.id
        );

        const fuhrparkeintragAnbauteilPromises =
          values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil) => {
                const fuhrparkeintragAnbauteilData = {
                  anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                  anbauteilNutzungsdauer:
                    fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                  anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  anbauteilStundenPreis:
                    fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                };
                return createFuhrparkeintragAnbauteil(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteilData
                );
              }
            );
          });

        await Promise.all(fuhrparkeintragAnbauteilPromises);

        const maschinentransportePromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.maschinentransporte.map(
              (maschinentransport) => {
                const maschinentransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  transportiertesFahrzeugId:
                    maschinentransport.transportiertesFahrzeugId,
                  abholortBaustellenId: maschinentransport.abholortBaustellenId,
                  abstellortBaustellenId:
                    maschinentransport.abstellortBaustellenId,
                  fuhrTyp: Buchungstyp.TR,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                };
                return createMaschinentransport(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  maschinentransportData
                );
              }
            );
          }
        );

        await Promise.all(maschinentransportePromises);

        const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialanfuhr.map((materialtransport) => {
              const materialtransportData = {
                baustelleId: taetigkeitsbericht.baustelleId,
                fuhrTyp: Buchungstyp.MA,
                buchungsTyp: Buchungstyp.MA,
                lieferscheinNummer: materialtransport.lieferscheinNummer,
                materialId: materialtransport.materialId,
                quelleAbladestelleId: materialtransport.quelleAbladestelleId,
                bemerkung: materialtransport.bemerkung,
                einheit: materialtransport.einheit,
                anzahlFuhren: materialtransport.anzahlFuhren,
                menge: materialtransport.menge,
                kostenProEinheit: materialtransport.kostenProEinheit,
                meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
                buchungsArt: materialtransport.buchungsArt,
              };
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialtransportData
              );
            });
          }
        );

        await Promise.all(materialanfuhrPromises);

        const materialabtrasportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialabtransport.map(
              (materialtransport) => {
                const materialtransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  fuhrTyp: materialtransport.fuhrTyp,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  materialId: materialtransport.materialId,
                  quelleAbladestelleId: materialtransport.quelleAbladestelleId,
                  zielAbladestelleId: materialtransport.zielAbladestelleId,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  menge: materialtransport.menge,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                  bsNr: materialtransport.bsNr,
                  entsorgerId: materialtransport.entsorgerId,
                  erzeugerId: materialtransport.erzeugerId,
                };
                return createMaterialtransport(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialtransportData
                );
              }
            );
          }
        );

        await Promise.all(materialabtrasportPromises);

        const materialtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialtransport.map(
              (materialtransport) => {
                const materialtransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  materialId: materialtransport.materialId,
                  zielBaustelleId: materialtransport.zielBaustelleId,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  menge: materialtransport.menge,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                };
                return createMaterialtransportZwischenBaustellen(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialtransportData
                );
              }
            );
          }
        );

        await Promise.all(materialtransportPromises);

        setStatus(undefined);

        navigate(
          `/taetigkeitsberichte/${taetigkeitsbericht.id}?expandedindexInherited=${expandedIndex}`
        );
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [
      navigate,
      createTaetigkeitsbericht,
      createPersonaleintrag,
      createFuhrparkeintrag,
      createFuhrparkeintragAnbauteil,
      createMaschinentransport,
      t,
    ]
  );

  return (
    <Layout
      title={capitalize(t("activity-report-creation-form"))}
      back={"/taetigkeitsberichte"}
    >
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            mitarbeiterId: "",
            baustelleId: "",
            datum: "",
            arbeitszeitNetto: 0,
            stundensatz: 0,
            lohnkosten: 0,
            bemerkung: "",
            fuhrparkeintraege: [] as ExtendedFuhrparkeintragDaten[],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => {
            var dauerVorhanden = values.arbeitszeitNetto;

            values.fuhrparkeintraege.map((fuhrparkeintrag) => {
              if (fuhrparkeintrag.fahrzeugId) {
                dauerVorhanden -= fuhrparkeintrag.fahrzeugNutzungsdauer;
              }
            });

            return (
              <>
                <Typography variant="h5">
                  {capitalize(t("new-activity-report"))}
                </Typography>
                <DialogContent>
                  <Form id="neuer-taetigkeitsbericht">
                    <TaetigkeitsberichtFieldsContent isNew={true} />
                    <FieldArray name="fuhrparkeintraege">
                      {({ push, remove }) => (
                        <>
                          {values.fuhrparkeintraege.map(
                            (fuhrparkeintrag, index) => (
                              <Box key={index} sx={{ marginTop: 2 }}>
                                <Box
                                  display={"grid"}
                                  sx={{
                                    gridTemplateColumns: ["1fr", "10fr .1fr"],
                                    gap: 2,
                                    marginTop: 2,
                                  }}
                                >
                                  <ExpandContextProvider
                                    id={`fuhrparkeintrag-${index}`}
                                  >
                                    <FuhrparkeintragEigenPersonalItem
                                      isNew={!fuhrparkeintrag.id}
                                      fuhrparkeintrag={fuhrparkeintrag}
                                      index={index}
                                      expanded={expandedIndex === index}
                                      handleChange={handleAccordionChange}
                                      taetigkeitsberichtDatum={values.datum}
                                      dauerVorhanden={
                                        dauerVorhanden > 0 ? dauerVorhanden : 0
                                      }
                                      isValid={isValid}
                                      isValidating={isValidating}
                                    />

                                    <IconButton
                                      onClick={() => remove(index)}
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ExpandContextProvider>
                                </Box>
                              </Box>
                            )
                          )}
                          <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                var werte = 0;

                                push({
                                  fahrzeugId: "",
                                  fahrzeugHerkunft: "",
                                  fahrzeugStundenPreis: 0,
                                  fahrzeugNutzungsdauer: 0,
                                  bemerkung: "",
                                  fuhrparkeintragAnbauteile: [
                                    {
                                      anbauteilId: "",
                                      anbauteilNutzungsdauer: 0,
                                      anbauteilAnzahl: 1,
                                      anbauteilStundenPreis: 0,
                                      anbauteilKosten: 0,
                                    },
                                  ],
                                  maschinentransporte: [],
                                  materialanfuhr: [],
                                  materialabtransport: [],
                                  materialtransport: [],
                                });

                                values.fuhrparkeintraege.map(() => {
                                  werte++;
                                });

                                if (werte > 0) {
                                  saveFuhrparkeintraege(values);
                                }
                              }}
                              disabled={isValidating || !isValid}
                            >
                              {capitalize(t("add-carpool"))}
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="neuer-taetigkeitsbericht"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </Paper>
    </Layout>
  );
}

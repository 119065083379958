import { FieldProps } from "formik";
import { useEffect, useMemo, useState } from "react";
import { ApiError, Fahrzeug, FahrzeugePage, HerkunftTyp } from "../../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";
import { useSucheFahrzeuge } from "../../../client/hooks";

export default function FahrzeugSelect({
  field,
  form,
  meta,
  label,
  fahrzeugHerkunft,
  ...props
}: {
  label: string;
  fahrzeugHerkunft: HerkunftTyp;
  required?: boolean;
} & FieldProps) {
  const searchFahrzeuge = useSucheFahrzeuge();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Fahrzeug>>([]);
  const [initialValue, setInitialValue] = useState(field.value);
  const [initialCount, setInitialCount] = useState(0);

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: FahrzeugePage) => void
        ) =>
          searchFahrzeuge({
            fahrzeugHerkunft: fahrzeugHerkunft,
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "kennzeichen",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchFahrzeuge, inputValue]
  );

  useEffect(() => {
    let active = true;
    if (initialValue || initialCount > 0) {
      fetchOptions({ input: inputValue }, (error, results) => {
        if (error) console.error("Error:", error);
        if (active) {
          const fetchedOptions = results ? results.items : [];

          let priorityIds = JSON.parse(
            localStorage.getItem("letzteFahrzeuge") ?? "[]"
          );
          priorityIds = priorityIds.reverse();

          const getPriorityIndex = (id: string) => {
            const index = priorityIds.indexOf(id);
            return index === -1 ? priorityIds.length : index;
          };

          setOptions([
            ...(field.value ? [field.value] : []),
            ...fetchedOptions.sort((a, b) => {
              const aPriority = getPriorityIndex(a.id);
              const bPriority = getPriorityIndex(b.id);
              return aPriority - bPriority;
            }),
          ]);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        if (initialCount === 0) {
          setInitialValue(false);
        }
        setInitialCount(initialCount + 1);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const fahrzeugId = value ? value.id : "";
        form.setFieldValue(field.name, fahrzeugId);
        if (fahrzeugId !== "") {
          let letzteFahrzeuge = JSON.parse(
            localStorage.getItem("letzteFahrzeuge") ?? "[]"
          );
          if (letzteFahrzeuge.includes(fahrzeugId)) {
            letzteFahrzeuge.splice(letzteFahrzeuge.indexOf(fahrzeugId), 1);
          }
          letzteFahrzeuge.push(fahrzeugId);
          localStorage.setItem(
            "letzteFahrzeuge",
            JSON.stringify(letzteFahrzeuge)
          );
        }
      }}
      getOptionLabel={(option: string | Fahrzeug) => {
        const fahrzeug = options.find((opt) => opt.id === field.value);
        if (fahrzeug) {
          return optionLabelPrimary(fahrzeug);
        } else {
          return "";
        }
      }}
      renderOption={(props, option: string | Fahrzeug) => (
        <ListItem {...props}>
          <ListItemText
            primary={
              typeof option === "string" ? option : optionLabelPrimary(option)
            }
            secondary={
              typeof option === "string"
                ? undefined
                : [option.gruppe, option.fabrikant, option.fahrzeugTyp]
                    .filter(Boolean)
                    .join(", ")
            }
          />
        </ListItem>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Fahrzeug"}
          {...props}
          {...params}
        />
      )}
    />
  );
}
function optionLabelPrimary(option: Fahrzeug): string {
  const label = [option.fabrikant, option.fahrzeugTyp, option.laufendeNummer]
    .filter(Boolean)
    .join(", ");

  switch (option.gruppe) {
    case "BAGGER":
      return label;
    case "RADLADER":
      return label;
    case "TELESKOP":
      return label;
    case "SIEBANLAGE":
      return label;
    case "WALZE":
      return label;
    case "BRECHER":
      return label;
    case "KOMPAKTLADER":
      return label;
    default:
      return option.kennzeichen ?? "";
  }
}

import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  Chip,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaschinentransport,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useDeleteFuhrparkeintrag,
  useGetMaterialtransporteZwischenListe,
  useGetTaetigkeitsbericht,
  useSucheFuhrparkeintraege,
  useSucheFuhrparkeintragAnbauteil,
  useSucheMaschinentransporte,
  useSucheMaterialtransporte,
  useSucheMaterialtransporteAbtransport,
  useSucheMaterialtransporteAnfuhr,
  useUpdateFuhrparkeintrag,
  useUpdateFuhrparkeintragAnbauteil,
  useUpdateMaschinentransport,
  useUpdateMaterialtransport,
  useUpdateMaterialtransportZwischen,
  useUpdateTaetigkeitsbericht,
} from "../../client/hooks";
import {
  Fuhrparkeintrag,
  FuhrparkeintragAnbauteil,
  Taetigkeitsbericht,
  Personaleintrag,
  Maschinentransport,
  Materialtransport,
  MaterialZwischenInfo,
} from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTaetigkeitsberichtButton from "../../components/taetigkeitsberichte/DeleteTaetigkeitsberichtButton";
import * as Yup from "yup";
import { ExpandContextProvider } from "../../expand";
import FuhrparkeintragFremdPersonalItem from "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragFremdPersonalItem";
import TaetigkeitsberichtFieldsContentExtern from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContentExtern";
import DeleteFuhrparkeintragButton from "../../components/taetigkeitsberichte/fuhrparkeintraege/DeleteFuhrparkeintragButton";
import { number } from "yup";

const FuhrparkeintragEigenPersonalItem = React.lazy(
  () =>
    import(
      "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragEigenPersonalItem"
    )
);

interface FuhrparkeintragData {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugId: string;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: FuhrparkeintragAnbauteil[];
  maschinentransporte: Maschinentransport[];
  materialanfuhr: Materialtransport[];
  materialabtransport: Materialtransport[];
  materialtransport: MaterialZwischenInfo[];
}

interface ExtendedTaetigkeitsberichtDaten {
  lieferantId: string;
  baustelleId: string;
  datum: string;
  fuhrparkeintraege: FuhrparkeintragData[];
}

const validationSchema = Yup.object().shape({
  lieferantId: yup.string().required(),
  baustelleId: yup.string().required(),
  datum: yup.string().required(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeugId: yup.mixed().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        fuhrparkeintragAnbauteile: Yup.array().of(
          Yup.object().shape({
            anbauteilId: yup.mixed().required(),
            anbauteilNutzungsdauer: yup.number().min(0).required(),
            anbauteilStundenPreis: yup.number().min(0).required(),
            anbauteilAnzahl: yup.number().min(1).required(),
            anbauteilKosten: yup.number().min(0),
          })
        ),
      })
    )
    .min(0, ""),
});

export default function TaetigkeitsberichtDetailsExtern() {
  const { id: taetigkeitsberichtId = "" } = useParams<{
    id: string;
  }>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stringExpandedindexInherited: string | null = queryParams.get(
    "expandedindexInherited"
  );
  const expandedindexInherited: number | null = stringExpandedindexInherited
    ? parseInt(stringExpandedindexInherited)
    : null;

  const { t } = useTranslation("taetigkeitsbericht");
  const navigate = useNavigate();
  const getTaetigkeitsbericht = useGetTaetigkeitsbericht();
  const updateTaetigkeitsbericht = useUpdateTaetigkeitsbericht();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const deleteFuhrparkeintrag = useDeleteFuhrparkeintrag();
  const updateFuhrparkeintrag = useUpdateFuhrparkeintrag();
  const searchFuhrparkeintraege = useSucheFuhrparkeintraege();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const updateFuhrparkeintragAnbauteil = useUpdateFuhrparkeintragAnbauteil();
  const searchFuhrparkeintragAnbauteil = useSucheFuhrparkeintragAnbauteil();
  const searchMaterialtransporte = useSucheMaterialtransporte();
  const searchMaschinentransport = useSucheMaschinentransporte();
  const searchMaterialtransportAnfuhr = useSucheMaterialtransporteAnfuhr();
  const searchMaterialtransporteAbtransport =
    useSucheMaterialtransporteAbtransport();
  const searchMaterialZwischenBaustellen =
    useGetMaterialtransporteZwischenListe();
  const updateMaterialtransport = useUpdateMaterialtransport();
  const createMaterialtransport = useCreateMaterialtransport();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();
  const updateMaterialtransportZwischenBaustellen =
    useUpdateMaterialtransportZwischen();
  const updateMaschinentransport = useUpdateMaschinentransport();
  const createMaschinentransport = useCreateMaschinentransport();

  const [fuhrparkeintraege, setFuhrparkeintraege] = useState<
    FuhrparkeintragData[]
  >([]);
  const [taetigkeitsbericht, setTaetigkeitsbericht] =
    useState<Taetigkeitsbericht | null>(null);

  const [expandedIndex, setExpandedIndex] = useState<number | null>(
    expandedindexInherited
  );

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  enum Buchungstyp {
    AR = "AR",
    ER = "ER",
    PS = "PS",
    FP = "FP",
    MA = "MA",
    AB = "AB",
    DP = "DP",
    TR = "TR",
  }
  useEffect(() => {
    const fetchFuhrparkeintraege = async () => {
      if (taetigkeitsberichtId) {
        const fuhrparkeintraegeData = await searchFuhrparkeintraege(
          taetigkeitsberichtId,
          {
            search: taetigkeitsberichtId,
            page: 0,
            limit: 20,
            sort: "created",
          }
        );

        const completeFuhrparkeintraege = await Promise.all(
          fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
            const fuhrparkeintragAnbauteileData =
              await searchFuhrparkeintragAnbauteil(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );
            const maschinentransporteData = await searchMaschinentransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialanfuhrData = await searchMaterialtransportAnfuhr(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialabtransportData =
              await searchMaterialtransporteAbtransport(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );
            const materialzwischenData = await searchMaterialZwischenBaustellen(
              taetigkeitsberichtId,
              fuhrparkeintrag.id
            );

            return {
              ...fuhrparkeintrag,
              fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
              maschinentransporte: maschinentransporteData.items,
              materialanfuhr: materialanfuhrData.items,
              materialabtransport: materialabtransportData.items,
              materialtransport: materialzwischenData.items,
            };
          })
        );

        setFuhrparkeintraege(completeFuhrparkeintraege);
      }
    };
    fetchFuhrparkeintraege();
  }, [taetigkeitsberichtId, searchFuhrparkeintraege]);

  useEffect(() => {
    const fetchTaetigkeitsbericht = async () => {
      if (taetigkeitsberichtId) {
        const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
        setTaetigkeitsbericht(data);
      }
    };
    fetchTaetigkeitsbericht();
  }, [taetigkeitsberichtId, getTaetigkeitsbericht]);

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: Fuhrparkeintrag[];
    }
  ) {
    try {
      const taetigkeitsbericht = await updateTaetigkeitsbericht(
        taetigkeitsberichtId,
        {
          baustelleId: values.baustelleId,
          datum: values.datum,
          taetigkeitsberichtTyp: "EXTERN",
          lieferantId: values.lieferantId,
        }
      );

      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          const fuhrparkeintragData = {
            fahrzeugId: fuhrparkeintrag.fahrzeugId,
            fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
            fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
            fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
            bemerkung: fuhrparkeintrag.bemerkung,
          };
          if (fuhrparkeintrag.id) {
            return updateFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              fuhrparkeintragData
            );
          } else {
            return createFuhrparkeintrag(
              taetigkeitsberichtId,
              fuhrparkeintragData
            );
          }
        }
      );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);

      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      const fuhrparkeintragAnbauteilPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
            (fuhrparkeintragAnbauteil) => {
              const fuhrparkeintragAnbauteilData = {
                anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                anbauteilNutzungsdauer:
                  fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                anbauteilStundenPreis:
                  fuhrparkeintragAnbauteil.anbauteilStundenPreis,
              };
              if (fuhrparkeintragAnbauteil.id) {
                return updateFuhrparkeintragAnbauteil(
                  taetigkeitsberichtId,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteil.id,
                  fuhrparkeintragAnbauteilData
                );
              } else {
                return createFuhrparkeintragAnbauteil(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteilData
                );
              }
            }
          );
        }
      );

      await Promise.all(fuhrparkeintragAnbauteilPromises);

      const maschinentransportePromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.maschinentransporte.map(
            (maschinentransport) => {
              const maschinentransportData = {
                baustelleId: taetigkeitsbericht.baustelleId,
                transportiertesFahrzeugId:
                  maschinentransport.transportiertesFahrzeugId,
                abholortBaustellenId: maschinentransport.abholortBaustellenId,
                abstellortBaustellenId:
                  maschinentransport.abstellortBaustellenId,
                fuhrTyp: Buchungstyp.TR,
                lieferscheinNummer: maschinentransport.lieferscheinNummer,
                bemerkung: maschinentransport.bemerkung,
              };
              if (maschinentransport.id) {
                return updateMaschinentransport(
                  taetigkeitsberichtId,
                  currentFuhrparkeintragId,
                  maschinentransport.id,
                  maschinentransportData
                );
              } else {
                return createMaschinentransport(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  maschinentransportData
                );
              }
            }
          );
        }
      );

      await Promise.all(maschinentransportePromises);

      const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => {
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              quelleAbladestelleId: materialanfuhr.quelleAbladestelleId,
              zielBaustelleId: materialanfuhr.zielBaustelleId,
              materialId: materialanfuhr.materialId,
              buchungsTyp: Buchungstyp.MA,
              fuhrTyp: Buchungstyp.MA,
              lieferscheinNummer: materialanfuhr.lieferscheinNummer,
              bemerkung: materialanfuhr.bemerkung,
              einheit: materialanfuhr.einheit,
              anzahlFuhren: materialanfuhr.anzahlFuhren,
              kostenProEinheit: materialanfuhr.kostenProEinheit,
              menge: materialanfuhr.menge,
              preisGesamt: materialanfuhr.preisGesamt,
              gesamtMenge: materialanfuhr.mengeGesamt,
              buchungsArt: materialanfuhr.buchungsArt,
            };
            if (materialanfuhr.id) {
              return updateMaterialtransport(
                taetigkeitsberichtId,
                currentFuhrparkeintragId,
                materialanfuhr.id,
                materialanfuhrData
              );
            } else {
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialanfuhrData
              );
            }
          });
        }
      );

      await Promise.all(materialanfuhrPromises);

      const materialabtransportPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialabtransport.map((materialtranport) => {
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              quelleAbladestelleId: materialtranport.quelleAbladestelleId,
              zielAbladestelleId: materialtranport.zielAbladestelleId,
              materialId: materialtranport.materialId,
              buchungsTyp: materialtranport.buchungsTyp,
              fuhrTyp: materialtranport.fuhrTyp,
              lieferscheinNummer: materialtranport.lieferscheinNummer,
              bemerkung: materialtranport.bemerkung,
              einheit: materialtranport.einheit,
              anzahlFuhren: materialtranport.anzahlFuhren,
              kostenProEinheit: materialtranport.kostenProEinheit,
              menge: materialtranport.menge,
              preisGesamt: materialtranport.preisGesamt,
              gesamtMenge: materialtranport.mengeGesamt,
              buchungsArt: materialtranport.buchungsArt,
              bsNr: materialtranport.bsNr,
              entsorgerId: materialtranport.entsorgerId,
              erzeugerId: materialtranport.erzeugerId,
            };
            if (materialtranport.id) {
              return updateMaterialtransport(
                taetigkeitsberichtId,
                currentFuhrparkeintragId,
                materialtranport.id,
                materialanfuhrData
              );
            } else {
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialanfuhrData
              );
            }
          });
        }
      );

      await Promise.all(materialabtransportPromises);

      const materialtransportPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.materialtransport.map((materialtranport) => {
            const materialanfuhrData = {
              baustelleId: taetigkeitsbericht.baustelleId,
              quelleBaustelleId: taetigkeitsbericht.baustelleId,
              zielBaustelleId: materialtranport.zielBaustelleId,
              materialId: materialtranport.materialId,
              buchungsTyp: materialtranport.buchungsTyp,
              lieferscheinNummer: materialtranport.lieferscheinNummer,
              bemerkung: materialtranport.bemerkung,
              einheit: materialtranport.einheit,
              anzahlFuhren: materialtranport.anzahlFuhren,
              kostenProEinheit: materialtranport.kostenProEinheit,
              menge: materialtranport.menge,
              preisGesamt: materialtranport.preisGesamt,
              gesamtMenge: materialtranport.mengeGesamt,
              buchungsArt: materialtranport.buchungsArt,
            };
            if (materialtranport.id) {
              return updateMaterialtransportZwischenBaustellen(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialtranport.id,
                materialanfuhrData
              );
            } else {
              return createMaterialtransportZwischenBaustellen(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialanfuhrData
              );
            }
          });
        }
      );

      await Promise.all(materialtransportPromises);

      const fuhrparkeintraegeData = await searchFuhrparkeintraege(
        taetigkeitsberichtId,
        {
          search: taetigkeitsberichtId,
          page: 0,
          limit: 20,
          sort: "created",
        }
      );

      const completeFuhrparkeintraege = await Promise.all(
        fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
          const fuhrparkeintragAnbauteileData =
            await searchFuhrparkeintragAnbauteil(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
          const maschinentransporteData = await searchMaschinentransport(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          const materialanfuhrData = await searchMaterialtransportAnfuhr(
            taetigkeitsberichtId,
            fuhrparkeintrag.id,
            {
              search: fuhrparkeintrag.id,
              page: 0,
              limit: 20,
              sort: "created",
            }
          );
          const materialabtransportData =
            await searchMaterialtransporteAbtransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
          const materialzwischenData = await searchMaterialZwischenBaustellen(
            taetigkeitsberichtId,
            fuhrparkeintrag.id
          );

          return {
            ...fuhrparkeintrag,
            fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
            maschinentransporte: maschinentransporteData.items,
            materialanfuhr: materialanfuhrData.items,
            materialabtransport: materialabtransportData.items,
            materialtransport: materialzwischenData.items,
          };
        })
      );

      setFuhrparkeintraege(completeFuhrparkeintraege);
      const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
      setTaetigkeitsbericht(data);
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten,
      formikHelpers: FormikHelpers<ExtendedTaetigkeitsberichtDaten>
    ) => {
      const { setSubmitting, setStatus, resetForm } = formikHelpers;
      try {
        // Update Taetigkeitsbericht first
        const updatedTaetigkeitsbericht = await updateTaetigkeitsbericht(
          taetigkeitsberichtId,
          {
            baustelleId: values.baustelleId,
            datum: values.datum,
            taetigkeitsberichtTyp: "EXTERN",
            lieferantId: values.lieferantId,
          }
        );

        // Create or update each fuhrparkeintrag
        const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
          (fuhrparkeintrag) => {
            const fuhrparkeintragData = {
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
            };
            if (fuhrparkeintrag.id) {
              // Update existing fuhrparkeintrag
              return updateFuhrparkeintrag(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                fuhrparkeintragData
              );
            } else {
              // Create new fuhrparkeintrag
              return createFuhrparkeintrag(
                taetigkeitsberichtId,
                fuhrparkeintragData
              );
            }
          }
        );

        const fuhrparkeintragResults = await Promise.all(
          fuhrparkeintragPromises
        );

        const fuhrparkeintragIds = fuhrparkeintragResults.map(
          (result) => result.id
        );

        const fuhrparkeintragAnbauteilPromises =
          values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil) => {
                const fuhrparkeintragAnbauteilData = {
                  anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                  anbauteilNutzungsdauer:
                    fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                  anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  anbauteilStundenPreis:
                    fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                };
                if (fuhrparkeintragAnbauteil.id) {
                  return updateFuhrparkeintragAnbauteil(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    fuhrparkeintragAnbauteil.id,
                    fuhrparkeintragAnbauteilData
                  );
                } else {
                  return createFuhrparkeintragAnbauteil(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    fuhrparkeintragAnbauteilData
                  );
                }
              }
            );
          });

        await Promise.all(fuhrparkeintragAnbauteilPromises);

        const maschinentransportePromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.maschinentransporte.map(
              (maschinentransport) => {
                const maschinentransportData = {
                  baustelleId: updatedTaetigkeitsbericht.baustelleId,
                  transportiertesFahrzeugId:
                    maschinentransport.transportiertesFahrzeugId,
                  abholortBaustellenId: maschinentransport.abholortBaustellenId,
                  abstellortBaustellenId:
                    maschinentransport.abstellortBaustellenId,
                  fuhrTyp: Buchungstyp.TR,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                };
                if (maschinentransport.id) {
                  return updateMaschinentransport(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    maschinentransport.id,
                    maschinentransportData
                  );
                } else {
                  return createMaschinentransport(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    maschinentransportData
                  );
                }
              }
            );
          }
        );

        await Promise.all(maschinentransportePromises);

        const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialanfuhr.map((materialanfuhr) => {
              const materialanfuhrData = {
                baustelleId: updatedTaetigkeitsbericht.baustelleId,
                quelleAbladestelleId: materialanfuhr.quelleAbladestelleId,
                zielBaustelleId: materialanfuhr.zielBaustelleId,
                materialId: materialanfuhr.materialId,
                buchungsTyp: Buchungstyp.MA,
                fuhrTyp: Buchungstyp.MA,
                lieferscheinNummer: materialanfuhr.lieferscheinNummer,
                bemerkung: materialanfuhr.bemerkung,
                einheit: materialanfuhr.einheit,
                anzahlFuhren: materialanfuhr.anzahlFuhren,
                kostenProEinheit: materialanfuhr.kostenProEinheit,
                menge: materialanfuhr.menge,
                meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
                buchungsArt: materialanfuhr.buchungsArt,
              };
              if (materialanfuhr.id) {
                return updateMaterialtransport(
                  taetigkeitsberichtId,
                  currentFuhrparkeintragId,
                  materialanfuhr.id,
                  materialanfuhrData
                );
              } else {
                return createMaterialtransport(
                  updatedTaetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialanfuhrData
                );
              }
            });
          }
        );

        await Promise.all(materialanfuhrPromises);

        const materialabtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialabtransport.map(
              (materialtransport) => {
                const materialanfuhrData = {
                  baustelleId: updatedTaetigkeitsbericht.baustelleId,
                  quelleAbladestelleId: materialtransport.quelleAbladestelleId,
                  zielAbladestelleId: materialtransport.zielAbladestelleId,
                  materialId: materialtransport.materialId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  fuhrTyp: materialtransport.fuhrTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  menge: materialtransport.menge,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                  bsNr: materialtransport.bsNr,
                  entsorgerId: materialtransport.entsorgerId,
                  erzeugerId: materialtransport.erzeugerId,
                };
                if (materialtransport.id) {
                  return updateMaterialtransport(
                    taetigkeitsberichtId,
                    currentFuhrparkeintragId,
                    materialtransport.id,
                    materialanfuhrData
                  );
                } else {
                  return createMaterialtransport(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    materialanfuhrData
                  );
                }
              }
            );
          }
        );

        await Promise.all(materialabtransportPromises);

        const materialtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialtransport.map(
              (materialtransport) => {
                const materialanfuhrData = {
                  baustelleId: updatedTaetigkeitsbericht.baustelleId,
                  zielBaustelleId: materialtransport.zielBaustelleId,
                  materialId: materialtransport.materialId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  menge: materialtransport.menge,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                };
                if (materialtransport.id) {
                  return updateMaterialtransportZwischenBaustellen(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    materialtransport.id,
                    materialanfuhrData
                  );
                } else {
                  return createMaterialtransportZwischenBaustellen(
                    updatedTaetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    materialanfuhrData
                  );
                }
              }
            );
          }
        );

        await Promise.all(materialtransportPromises);

        navigate(`/fremdleistungen/`, { replace: true });

        setStatus(undefined);
        const fuhrparkeintraegeData = await searchFuhrparkeintraege(
          taetigkeitsberichtId,
          {
            search: taetigkeitsberichtId,
            page: 0,
            limit: 20,
            sort: "created",
          }
        );

        const completeFuhrparkeintraege = await Promise.all(
          fuhrparkeintraegeData.items.map(async (fuhrparkeintrag) => {
            const fuhrparkeintragAnbauteileData =
              await searchFuhrparkeintragAnbauteil(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );
            const maschinentransporteData = await searchMaschinentransport(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialanfuhrData = await searchMaterialtransportAnfuhr(
              taetigkeitsberichtId,
              fuhrparkeintrag.id,
              {
                search: fuhrparkeintrag.id,
                page: 0,
                limit: 20,
                sort: "created",
              }
            );
            const materialabtransportData =
              await searchMaterialtransporteAbtransport(
                taetigkeitsberichtId,
                fuhrparkeintrag.id,
                {
                  search: fuhrparkeintrag.id,
                  page: 0,
                  limit: 20,
                  sort: "created",
                }
              );
            const materialzwischenData = await searchMaterialZwischenBaustellen(
              taetigkeitsberichtId,
              fuhrparkeintrag.id
            );
            const materialanfuhrListe = materialanfuhrData.items;
            const abtranporteListe = materialabtransportData.items;
            return {
              ...fuhrparkeintrag,
              fuhrparkeintragAnbauteile: fuhrparkeintragAnbauteileData.items,
              maschinentransporte: maschinentransporteData.items,
              materialanfuhr: materialanfuhrData.items,
              materialabtransport: materialabtransportData.items,
              materialtransport: materialzwischenData.items,
            };
          })
        );

        setFuhrparkeintraege(completeFuhrparkeintraege);
        const data = await getTaetigkeitsbericht(taetigkeitsberichtId);
        setTaetigkeitsbericht(data);
        resetForm({
          values: {
            ...values,
            // @ts-ignore
            fuhrparkeintraege: fuhrparkeintraege.map((fuhrparkeintrag) => ({
              id: fuhrparkeintrag.id,
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
              fuhrparkeintragAnbauteile:
                fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
                  (fuhrparkeintragAnbauteil) => ({
                    id: fuhrparkeintragAnbauteil.id,
                    anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                    anbauteilNutzungsdauer:
                      fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                    anbauteilStundenPreis:
                      fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                    anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  })
                ),
              maschinentransporte: fuhrparkeintrag.maschinentransporte.map(
                (maschinentransport) => ({
                  id: maschinentransport.id,
                  baustelleId: maschinentransport.baustelleId,
                  transportiertesFahrzeugId:
                    maschinentransport.transportiertesFahrzeugId,
                  abholortBaustellenId: maschinentransport.abholortBaustellenId,
                  abstellortBaustellenId:
                    maschinentransport.abstellortBaustellenId,
                  fuhrTyp: maschinentransport.fuhrTyp,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                })
              ),
              materialanfuhr: fuhrparkeintrag.materialanfuhr.map(
                (materialanfuhr) => ({
                  id: materialanfuhr.id,
                  baustelleId: materialanfuhr.baustelleId,
                  quelleAbladestelleId: materialanfuhr.quelleAbladestelleId,
                  zielBaustelleId: materialanfuhr.zielBaustelleId,
                  materialId: materialanfuhr.materialId,
                  preisGesamt: materialanfuhr.preisGesamt,
                  mengeGesamt: materialanfuhr.mengeGesamt,
                  menge: materialanfuhr.menge,
                  fuhrTyp: materialanfuhr.fuhrTyp,
                  einheit: materialanfuhr.einheit,
                  lieferscheinNummer: materialanfuhr.lieferscheinNummer,
                  bemerkung: materialanfuhr.bemerkung,
                  meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
                  buchungsArt: materialanfuhr.buchungsArt,
                })
              ),
              materialabtransport: fuhrparkeintrag.materialabtransport.map(
                (materialabtransport) => ({
                  id: materialabtransport.id,
                  baustelleId: materialabtransport.baustelleId,
                  fuhrTyp: materialabtransport.fuhrTyp,
                  buchungsTyp: materialabtransport.buchungsTyp,
                  quelleAbladestelleId:
                    materialabtransport.quelleAbladestelleId,
                  zielAbladestelleId: materialabtransport.zielAbladestelleId,
                  zielBaustelleId: materialabtransport.zielBaustelleId,
                  quelleBaustelleId: materialabtransport.quelleBaustelleId,
                  materialId: materialabtransport.materialId,
                  preisGesamt: materialabtransport.preisGesamt,
                  mengeGesamt: materialabtransport.mengeGesamt,
                  menge: materialabtransport.menge,
                  einheit: materialabtransport.einheit,
                  lieferscheinNummer: materialabtransport.lieferscheinNummer,
                  bemerkung: materialabtransport.bemerkung,
                  meLadeVolumenFahrzeug:
                    materialabtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialabtransport.buchungsArt,
                  bsNr: materialabtransport.bsNr,
                  entsorgerId: materialabtransport.entsorgerId,
                  erzeugerId: materialabtransport.erzeugerId,
                })
              ),
            })),
          },
        });
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [
      navigate,
      updateTaetigkeitsbericht,
      createFuhrparkeintrag,
      updateFuhrparkeintrag,
      taetigkeitsberichtId,
    ]
  );

  if (!taetigkeitsbericht) {
    return <div>Loading...</div>;
  }

  return (
    <Layout title={capitalize(t("activity-report"))} back="/fremdleistungen">
      <Paper
        elevation={1}
        sx={{
          marginTop: 2,
          padding: 3,
          marginLeft: 2,
          marginRight: 2,
          position: "relative",
        }}
      >
        {taetigkeitsbericht.deleted === undefined ? (
          <DeleteTaetigkeitsberichtButton
            id={taetigkeitsberichtId}
          ></DeleteTaetigkeitsberichtButton>
        ) : (
          <></>
        )}
        <Formik
          enableReinitialize={true}
          initialValues={{
            lieferantId: taetigkeitsbericht?.lieferantId ?? "",
            baustelleId: taetigkeitsbericht?.baustelleId ?? "",
            datum: taetigkeitsbericht?.datum ?? "",
            // @ts-ignore
            fuhrparkeintraege: fuhrparkeintraege.map((fuhrparkeintrag) => ({
              id: fuhrparkeintrag.id,
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
              fuhrparkeintragAnbauteile:
                fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
                  (fuhrparkeintragAnbauteil) => ({
                    id: fuhrparkeintragAnbauteil.id,
                    anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                    anbauteilNutzungsdauer:
                      fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                    anbauteilStundenPreis:
                      fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                    anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  })
                ),
              maschinentransporte: fuhrparkeintrag.maschinentransporte.map(
                (maschinentransport) => ({
                  id: maschinentransport.id,
                  baustelleId: maschinentransport.baustelleId,
                  transportiertesFahrzeugId:
                    maschinentransport.transportiertesFahrzeugId,
                  abholortBaustellenId: maschinentransport.abholortBaustellenId,
                  abstellortBaustellenId:
                    maschinentransport.abstellortBaustellenId,
                  fuhrTyp: maschinentransport.fuhrTyp,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                })
              ),
              materialanfuhr: fuhrparkeintrag.materialanfuhr.map(
                (materialanfuhr) => ({
                  id: materialanfuhr.id,
                  baustelleId: materialanfuhr.baustelleId,
                  quelleAbladestelleId: materialanfuhr.quelleAbladestelleId,
                  zielBaustelleId: materialanfuhr.zielBaustelleId,
                  materialId: materialanfuhr.materialId,
                  preisGesamt: materialanfuhr.preisGesamt,
                  mengeGesamt: materialanfuhr.mengeGesamt,
                  menge: materialanfuhr.menge,
                  fuhrTyp: materialanfuhr.fuhrTyp,
                  einheit: materialanfuhr.einheit,
                  lieferscheinNummer: materialanfuhr.lieferscheinNummer,
                  bemerkung: materialanfuhr.bemerkung,
                  meLadeVolumenFahrzeug: materialanfuhr.meLadeVolumenFahrzeug,
                  buchungsArt: materialanfuhr.buchungsArt,
                  kostenProEinheit: materialanfuhr.kostenProEinheit,
                  anzahlFuhren: materialanfuhr.anzahlFuhren,
                })
              ),
              materialabtransport: fuhrparkeintrag.materialabtransport.map(
                (materialabtransport) => ({
                  id: materialabtransport.id,
                  baustelleId: materialabtransport.baustelleId,
                  fuhrTyp: materialabtransport.fuhrTyp,
                  buchungsTyp: materialabtransport.buchungsTyp,
                  quelleAbladestelleId:
                    materialabtransport.quelleAbladestelleId,
                  zielAbladestelleId: materialabtransport.zielAbladestelleId,
                  zielBaustelleId: materialabtransport.zielBaustelleId,
                  quelleBaustelleId: materialabtransport.quelleBaustelleId,
                  materialId: materialabtransport.materialId,
                  preisGesamt: materialabtransport.preisGesamt,
                  mengeGesamt: materialabtransport.mengeGesamt,
                  menge: materialabtransport.menge,
                  einheit: materialabtransport.einheit,
                  lieferscheinNummer: materialabtransport.lieferscheinNummer,
                  bemerkung: materialabtransport.bemerkung,
                  meLadeVolumenFahrzeug:
                    materialabtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialabtransport.buchungsArt,
                  bsNr: materialabtransport.bsNr,
                  entsorgerId: materialabtransport.entsorgerId,
                  erzeugerId: materialabtransport.erzeugerId,
                  kostenProEinheit: materialabtransport.kostenProEinheit,
                  anzahlFuhren: materialabtransport.anzahlFuhren,
                })
              ),
              materialtransport: fuhrparkeintrag.materialtransport.map(
                (materialtransport) => ({
                  id: materialtransport.id,
                  baustelleId: taetigkeitsbericht.baustelleId,
                  zielBaustelleId: materialtransport.zielBaustelleId,
                  materialId: materialtransport.materialId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  menge: materialtransport.menge,
                  preisGesamt: materialtransport.preisGesamt,
                  gesamtMenge: materialtransport.mengeGesamt,
                  buchungsArt: materialtransport.buchungsArt,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                })
              ),
            })),
          }}
          validationSchema={validationSchema}
          // @ts-ignore
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => (
            <>
              <Typography variant="h5">
                {capitalize(t("activity-report"))}
                {taetigkeitsbericht.deleted === undefined ? (
                  ""
                ) : (
                  <Chip
                    size="small"
                    icon={<DeleteIcon />}
                    label={"gelöscht"}
                    color="error"
                  />
                )}
              </Typography>
              <DialogContent>
                <Form id="details-taetigkeitsbericht">
                  <TaetigkeitsberichtFieldsContentExtern isNew={false} />
                  <FieldArray name="fuhrparkeintraege">
                    {({ push, remove }) => (
                      <>
                        {values.fuhrparkeintraege.map(
                          (fuhrparkeintrag, index) => (
                            <Box key={index} sx={{ marginTop: 2 }}>
                              <Box
                                display={"grid"}
                                sx={{
                                  gridTemplateColumns: ["1fr", "10fr .1fr"],
                                  gap: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ExpandContextProvider
                                  id={`fuhrparkeintrag-${index}`}
                                >
                                  <FuhrparkeintragFremdPersonalItem
                                    isNew={!fuhrparkeintrag.id}
                                    // @ts-ignore
                                    fuhrparkeintrag={fuhrparkeintrag}
                                    index={index}
                                    expanded={expandedIndex === index}
                                    handleChange={handleAccordionChange}
                                    taetigkeitsberichtDatum={values.datum}
                                    dauerVorhanden={0}
                                    isValidating={isValidating}
                                    isValid={isValid}
                                  />
                                  <DeleteFuhrparkeintragButton
                                    remove={remove}
                                    index={index}
                                    id={fuhrparkeintrag.id}
                                    taetigkeitsberichtId={taetigkeitsberichtId}
                                  />
                                </ExpandContextProvider>
                              </Box>
                            </Box>
                          )
                        )}
                        <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              var werte = 0;

                              const newEntry = {
                                fahrzeugId: "",
                                fahrzeugHerkunft: "",
                                fahrzeugStundenPreis: 0,
                                fahrzeugNutzungsdauer: 0,
                                bemerkung: "",
                                fuhrparkeintragAnbauteile: [
                                  {
                                    anbauteilId: "",
                                    anbauteilNutzungsdauer: 0,
                                    anbauteilAnzahl: 1,
                                    anbauteilStundenPreis: 0,
                                    anbauteilKosten: 0,
                                  },
                                ],
                                maschinentransporte: [],
                                materialanfuhr: [],
                                materialabtransport: [],
                                materialtransport: [],
                              };

                              values.fuhrparkeintraege.map(() => {
                                werte++;
                              });

                              if (werte > 0) {
                                // @ts-ignore
                                saveFuhrparkeintraege(values).then(() => {
                                  push(newEntry);
                                });
                              } else {
                                push(newEntry);
                              }
                            }}
                            disabled={isValidating || !isValid}
                          >
                            {capitalize(t("add-carpool"))}
                          </Button>
                        </Box>
                      </>
                    )}
                  </FieldArray>
                </Form>
              </DialogContent>
              <DialogActions>
                <SubmitButton
                  form="details-taetigkeitsbericht"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={isSubmitting}
                  disabled={isValidating || !isValid || !dirty}
                >
                  {capitalize(t("save"))}
                </SubmitButton>
              </DialogActions>
            </>
          )}
        </Formik>
      </Paper>
    </Layout>
  );
}

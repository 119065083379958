import { FieldProps } from "formik";
import {
  useSucheBaustellen,
  useGetKunde,
  useFetchMaterialsByAbladestelleId,
  useGetAbfallart,
} from "../../client/hooks"; // Import the useGetKunde hook
import { useEffect, useMemo, useState } from "react";
import {
  ApiError,
  Baustelle,
  KundeListe,
  Material,
  MaterialListe,
} from "../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";

export default function MaterialienSelect({
  field,
  form,
  meta,
  label,
  abladestelleId,
  ...props
}: {
  abladestelleId: string;
  label: string;
  required?: boolean;
} & FieldProps) {
  const searchMaterial = useFetchMaterialsByAbladestelleId();
  const getKunde = useGetKunde();
  const getAbfallart = useGetAbfallart();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Material>>([]);
  const [kundenNamen, setKundenNamen] = useState<Record<string, string>>({});

  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: MaterialListe) => void
        ) =>
          searchMaterial(abladestelleId)
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchMaterial, inputValue, abladestelleId]
  );

  useEffect(() => {
    let active = true;
    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions, getKunde, kundenNamen]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        const materialId = value ? value.id : "";
        const material = value as Material;
        form.setFieldValue(field.name, materialId);
        if (material) {
          getAbfallart(material.abfallartId).then((abfallart) => {
            form.setFieldValue("abfallartKey", abfallart.key);
          });
        }
      }}
      getOptionLabel={(option: string | Material) => {
        const material = options.find((opt) => opt.id === field.value);
        return material?.name ?? "";
      }}
      renderOption={(props, option: string | Material) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={typeof option === "string" ? option : option.name}
              secondary={
                typeof option === "string"
                  ? undefined
                  : [option.name].filter(Boolean).join(", ")
              }
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField name={field.name} label={label} {...props} {...params} />
      )}
    />
  );
}

import {
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LabelListItem from "../../components/LabelListItem";
import { useParams } from "react-router";
import { useBaustelle } from "../../client/queries";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import { TextField } from "formik-mui";
import {
  useGetUmsatzMengenAuswertungByBaustellenId,
  useGetUmsatzMengenAuswertungByDate,
  useSucheUmsatzAuswertungByBaustellenId,
  useSucheUmsatzAuswertungByBaustellenIdAndByDate,
  useUpdateBaustelle,
} from "../../client/hooks";
import {
  BaustelleDaten,
  UmsatzAuswertungDaten,
  UmsatzAuswertungListe,
  UmsatzmengenAuswertung,
  UmsatzmengenAuswertungRequest,
} from "../../api";
import { useT } from "../../i18n";
import * as yup from "yup";
import SubmitButton from "../../components/SubmitButton";
import { useMutation, useQueryClient } from "react-query";

export default function DetailsBaustellenKonto() {
  const { id } = useParams<{ id: string }>();
  const baustelle = useBaustelle(id ?? "");
  const fetchUmsatzAuswertung = useSucheUmsatzAuswertungByBaustellenId();
  const fetchMenge = useGetUmsatzMengenAuswertungByBaustellenId();
  const { mutateAsync: vonMutate } = useKontoAuswertungByDate(id ?? "", "VON");
  const { mutateAsync: anMutate } = useKontoAuswertungByDate(id ?? "", "AN");
  const { mutateAsync } = useUmsatzAuwertung(id ?? "");
  const [data, setData] = useState<UmsatzAuswertungListe>();
  const [kontoVon, setKontoVon] = useState<UmsatzmengenAuswertung>();
  const [kontoAn, setKontoAn] = useState<UmsatzmengenAuswertung>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    // Define the ID you want to search for
    const baustellenId = id ?? "";

    // Fetch data using the custom hook
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetchUmsatzAuswertung(baustellenId);
        setData(result);
        const kontoVon = await fetchMenge(baustellenId, "VON");
        setKontoVon(kontoVon);
        const kontoAn = await fetchMenge(baustellenId, "AN");
        setKontoAn(kontoAn);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, fetchUmsatzAuswertung]);
  const onSubmit = useCallback(
    async (
      values: { startDate: string; endDate: string },
      formikHelpers: FormikHelpers<{ startDate: string; endDate: string }>
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      if (values.startDate !== null && values.endDate !== null) {
        const result = await mutateAsync({
          startDate: values.startDate,
          endDate: values.endDate,
        });
        setData(result);
        const kontoVon = await vonMutate({
          startDate: values.startDate,
          endDate: values.endDate,
        });
        if (kontoVon !== undefined) {
          setKontoVon(kontoVon);
        }
        const kontoAn = await anMutate({
          startDate: values.startDate,
          endDate: values.endDate,
        });
        if (kontoAn !== undefined) {
          setKontoAn(kontoAn);
        }
      }
      setStatus(undefined);
      try {
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    []
  );
  let schema = yup.object().shape({
    startDate: yup.string().required(),
    endDate: yup.string().required(),
  });
  const sumKosten = data
    ? data.items.reduce(
        (sum, row) => sum + parseFloat(`${row.kosten ?? "0"}`),
        0
      )
    : 0;
  const sumErtrag = data
    ? data.items.reduce(
        (sum, row) => sum + parseFloat(`${row.ertrag ?? "0"}`),
        0
      )
    : 0;
  const sumSaldo = data
    ? data.items.reduce(
        (sum, row) => sum + parseFloat(`${row.saldo ?? "0"}`),
        0
      )
    : 0;
  const { t } = useT("konto");
  return (
    <Paper
      elevation={1}
      sx={{
        marginTop: 2,
        padding: 3,
        marginLeft: 2,
        marginRight: 2,
        position: "relative",
      }}
    >
      <Typography variant={"h5"}>Konto</Typography>
      <Typography sx={{ mt: 2, mb: 1 }} variant={"h6"}>
        {"Baustelle " + baustelle.nummer + " " + baustelle.name}
      </Typography>
      <Formik
        validationSchema={schema}
        initialValues={{
          startDate: "",
          endDate: new Date().toISOString().split("T")[0],
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValidating, isValid, dirty, values }) => (
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Form id="details-umsatz">
                <Field
                  component={TextField}
                  name="startDate"
                  label={t("start-date")}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ max: values.endDate }}
                />
                <Field
                  sx={{ ml: 2 }}
                  component={TextField}
                  name="endDate"
                  label={t("end-date")}
                  type="date"
                  inputProps={{ min: values.startDate }}
                />
              </Form>
              <SubmitButton
                sx={{ height: "50%" }}
                form="details-umsatz"
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isSubmitting || !isValid || !dirty}
              >
                Einschränken
              </SubmitButton>
            </Stack>
          </>
        )}
      </Formik>
      <TableContainer
        component={Paper}
        sx={{ width: "50%", marginLeft: 0, mt: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "1.1rem", border: "1px solid #ddd" }}>
                {t("booking-short-type")}
              </TableCell>
              <TableCell
                align={"right"}
                sx={{ fontSize: "1.1rem", border: "1px solid #ddd" }}
              >
                {t("costs")}
              </TableCell>
              <TableCell
                align={"right"}
                sx={{ fontSize: "1.1rem", border: "1px solid #ddd" }}
              >
                {t("yields")}
              </TableCell>
              <TableCell
                align={"right"}
                sx={{ fontSize: "1.1rem", border: "1px solid #ddd" }}
              >
                {t("balance")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.items.length > 0 ? (
              data.items.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                      border: "1px solid #ddd",
                    }}
                  >
                    {row.buchungsArt}
                  </TableCell>
                  <TableCell
                    align={"right"}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                      border: "1px solid #ddd",
                    }}
                  >
                    {row.kosten != null && !isNaN(row.kosten)
                      ? row.kosten
                          ?.toFixed(2)
                          .replace(".", ",")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                      : ""}
                  </TableCell>
                  <TableCell
                    align={"right"}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                      border: "1px solid #ddd",
                    }}
                  >
                    {row.ertrag != null && !isNaN(row.ertrag)
                      ? row.ertrag
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                      : ""}
                  </TableCell>
                  <TableCell
                    align={"right"}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                      border: "1px solid #ddd",
                    }}
                  >
                    {row.saldo != null && !isNaN(row.saldo)
                      ? row.saldo
                          ?.toFixed(2)
                          .replace(".", ",")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                      : ""}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    {t("no-entries")}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
          <TableFooter>
            {data && data.items.length > 0 ? (
              <TableRow>
                <TableCell sx={{ fontSize: "1rem" }}>
                  <Typography variant="subtitle1">{t("sum") + ":"}</Typography>
                </TableCell>
                <TableCell
                  align={"right"}
                  sx={{ fontSize: "1rem", border: "1px solid #ddd" }}
                >
                  {sumKosten != null && !isNaN(sumKosten)
                    ? sumKosten
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                    : ""}
                </TableCell>
                <TableCell
                  align={"right"}
                  sx={{ fontSize: "1rem", border: "1px solid #ddd" }}
                >
                  {sumErtrag != null && !isNaN(sumErtrag)
                    ? sumKosten
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                    : ""}
                </TableCell>
                <TableCell
                  align={"right"}
                  sx={{ fontSize: "1rem", border: "1px solid #ddd" }}
                >
                  {sumSaldo != null && !isNaN(sumSaldo)
                    ? sumSaldo
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €"
                    : ""}
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        sx={{ width: "50%", gridTemplateColumns: ["1fr 1fr"] }}
        display={"grid"}
      >
        <Card>
          <Typography sx={{ ml: 2, mt: 2 }}>
            {"Material von Baustelle -> Deponie/Abladestellen"}
          </Typography>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LabelListItem label={"Eigene Fuhren"}>
                  {kontoVon?.eigeneFuhren}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Eigene Kubikm"}>
                  {kontoVon?.eigeneKubikmeter}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Eigene Tonnen"}>
                  {kontoVon?.eigeneTonne}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Fremde Fuhren"}>
                  {kontoVon?.fremdeFuhren}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Fremde Kubikm"}>
                  {kontoVon?.fremdeKubikmeter}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Fremde Tonnen"}>
                  {kontoVon?.fremdeTonne}
                </LabelListItem>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Gesamte Fuhren"}>
                  {(kontoVon?.eigeneFuhren ?? 0) +
                    (kontoVon?.fremdeFuhren ?? 0)}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Kubikmeter gesamt"}>
                  {(kontoVon?.eigeneKubikmeter ?? 0) +
                    (kontoVon?.fremdeKubikmeter ?? 0)}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Gesamt Tonnen"}>
                  {(kontoVon?.eigeneTonne ?? 0) + (kontoVon?.fremdeTonne ?? 0)}
                </LabelListItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ ml: 5 }}>
          <Typography sx={{ ml: 2, mt: 2 }}>
            {"Material an Baustelle"}
          </Typography>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LabelListItem label={"Eigene Fuhren"}>
                  {kontoAn?.eigeneFuhren}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Eigene Kubikm"}>
                  {kontoAn?.eigeneKubikmeter}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Eigene Tonnen"}>
                  {kontoAn?.eigeneTonne}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Fremde Fuhren"}>
                  {kontoAn?.fremdeFuhren}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Fremde Kubikm"}>
                  {kontoAn?.fremdeKubikmeter}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Fremde Tonnen"}>
                  {kontoAn?.fremdeTonne}
                </LabelListItem>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Gesamte Fuhren"}>
                  {(kontoAn?.eigeneFuhren ?? 0) + (kontoAn?.fremdeFuhren ?? 0)}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Kubikmeter gesamt"}>
                  {(kontoAn?.eigeneKubikmeter ?? 0) +
                    (kontoAn?.fremdeKubikmeter ?? 0)}
                </LabelListItem>
              </Grid>

              <Grid item xs={4}>
                <LabelListItem label={"Gesamt Tonnen"}>
                  {(kontoAn?.eigeneTonne ?? 0) + (kontoAn?.fremdeTonne ?? 0)}
                </LabelListItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
}
function useUmsatzAuwertung(id: string) {
  const sucheUmsatzAuswertung =
    useSucheUmsatzAuswertungByBaustellenIdAndByDate();
  const queryClient = useQueryClient();

  return useMutation(
    (input: UmsatzAuswertungDaten) => {
      return sucheUmsatzAuswertung(id, input);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["baustellen", id]);
      },
    }
  );
}

function useKontoAuswertungByDate(id: string, richtung: string) {
  const sucheKontoAuswertung = useGetUmsatzMengenAuswertungByDate();
  const queryClient = useQueryClient();

  return useMutation(
    (input: UmsatzmengenAuswertungRequest) => {
      return sucheKontoAuswertung(id, richtung, input);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["baustellen", id]);
      },
    }
  );
}

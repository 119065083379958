import { FieldProps } from "formik";
import {
  useGetEntsorger,
  useGetErzeuger,
  useSucheEntsorger,
  useSucheErzeuger,
} from "../../client/hooks";
import { useEffect, useMemo, useState } from "react";
import {
  ApiError,
  EntsorgerInfo,
  EntsorgerListe,
  Erzeuger,
  ErzeugerListe,
} from "../../api";
import {
  ListItem,
  ListItemText,
  debounce,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { Autocomplete } from "formik-mui";
import { useT } from "../../i18n";

export default function ErzeugerSelect({
  field,
  form,
  meta,
  label,
  ...props
}: {
  label: string;
  required?: boolean;
} & FieldProps) {
  const { t } = useT("user");
  const searchErzeuger = useSucheErzeuger();
  const getErzeuger = useGetErzeuger();
  const [inputValue, setInputValue] = useState(field.value);
  const [options, setOptions] = useState<ReadonlyArray<Erzeuger>>([]);
  const [selectedErzeuger, setSelectedErzeuger] = useState<Erzeuger | null>(
    null
  );
  useEffect(() => {
    if (field.value) {
      getErzeuger(field.value)
        .then((erzeuger) => setSelectedErzeuger(erzeuger))
        .catch((error) => console.error("Error fetching entsorger:", error));
    }
  }, []);
  const handleErzeugerChange = async (value: Erzeuger | null) => {
    if (value) {
      // Fetch the full benutzer data using the provided ID
      try {
        const erzeuger = await getErzeuger(value.id);
        console.log("entsorger Nr", erzeuger.nummer);
        setSelectedErzeuger(erzeuger);
        // Set the field value to the entsorger's ID
        form.setFieldValue(field.name, erzeuger.id);
      } catch (error) {
        console.error("Error fetching entsorger:", error);
      }
    } else {
      // If no benutzer is selected, clear the selection
      setSelectedErzeuger(null);
      form.setFieldValue(field.name, "");
    }
  };
  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (error?: ApiError, results?: ErzeugerListe) => void
        ) =>
          searchErzeuger({
            search: inputValue === "" ? request.input : inputValue,
            page: 0,
            limit: 20,
            sort: "nummer",
          })
            .then((results) => {
              callback(undefined, results);
            })
            .catch((error) => callback(error)),
        400
      ),
    [searchErzeuger, inputValue]
  );

  useEffect(() => {
    let active = true;

    fetchOptions({ input: inputValue }, (error, results) => {
      if (error) console.error("Error:", error);
      if (active) {
        setOptions([
          ...(field.value ? [field.value] : []),
          ...(results ? results.items : []),
        ]);
      }
    });

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetchOptions]);

  return (
    <Autocomplete
      field={field}
      form={form}
      meta={meta}
      autoComplete
      includeInputInList
      filterSelectedOptions
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // @ts-ignore
        handleErzeugerChange(value);
      }}
      getOptionLabel={(option: string | Erzeuger) => {
        return typeof option === "string"
          ? selectedErzeuger?.nummer ?? ""
          : option.nummer;
      }}
      renderOption={(props, option: string | Erzeuger) => {
        return (
          <ListItem {...props}>
            <ListItemText
              primary={typeof option === "string" ? option : option.nummer}
            />
          </ListItem>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={field.name}
          label={"Erzeuger-Nr"}
          {...props}
          {...params}
        />
      )}
    />
  );
}

import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import DataTable from "../data/DataTable";
import { CreateAnhang, EingangsrechnungInfo } from "../../api";
import { useCreateRechnungsAnhang } from "../../client/hooks";
import { useMutation, useQueryClient } from "react-query";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import DatevProtokolleButton from "../rechnungen/DatevProtokolleButton";

export type EingangsrechnungenFilter = {
  search?: string;
  status?: string;
  kundenId?: string;
};

export type Props = Omit<DataRequest<EingangsrechnungenFilter>, "filter"> &
  Partial<Pick<DataRequest<EingangsrechnungenFilter>, "filter">>;

export default function EingangsrechnungenDataTable({ ...input }: Props) {
  const request = useDataRequest<EingangsrechnungenFilter>({
    filter: {},
    ...input,
  });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <EingangsrechnungenResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<EingangsrechnungenFilter>) {
  const [{ search, status }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useT("eingangsrechnung");
  const { mutateAsync } = useSaveRechnungsanhang();
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files); // Convert FileList to Array for easier iteration
      for (const file of fileArray) {
        const rechnnungsAnhang = await mutateAsync({
          type: file.type,
          filename: file.name,
          file: file,
          name: file.name,
        });
      }
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <FormControl size="small" sx={{ width: "150px", ml: 1 }}>
            <InputLabel>Belegstatus</InputLabel>
            <Select
              label="Belegstatus"
              size="small"
              value={status ?? "_all"}
              onChange={(e) =>
                setField(
                  "status",
                  e.target.value !== "_all" ? e.target.value : "_all"
                )
              }
            >
              <MenuItem key="_all" value="_all">
                Alle
              </MenuItem>
              <MenuItem key="IN_ERKENNUNG" value="IN_ERKENNUNG">
                In Erkennung
              </MenuItem>
              <MenuItem key="OFFEN" value="OFFEN">
                Offen
              </MenuItem>
              <MenuItem key="IN_PRUEFUNG" value="IN_PRUEFUNG">
                In Prüfung
              </MenuItem>
              <MenuItem key="FREIGEGEBEN" value="FREIGEGEBEN">
                Freigegeben
              </MenuItem>
              <MenuItem key="ABGELEHNT" value="ABGELEHNT">
                Abgelehnt
              </MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" component="label" color={"secondary"}>
            {t("upload")}
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={handleFileChange}
              multiple={true}
            />
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <FormControl size="small" sx={{ width: "150px", ml: 1 }}>
            <InputLabel>Belegstatus</InputLabel>
            <Select
              label="Belegstatus"
              size="small"
              value={status ?? "_all"}
              onChange={(e) =>
                setField(
                  "status",
                  e.target.value !== "_all" ? e.target.value : "_all"
                )
              }
            >
              <MenuItem key="_all" value="_all">
                Alle
              </MenuItem>
              <MenuItem key="IN_ERKENNUNG" value="IN_ERKENNUNG">
                In Erkennung
              </MenuItem>
              <MenuItem key="OFFEN" value="OFFEN">
                Offen
              </MenuItem>
              <MenuItem key="IN_PRUEFUNG" value="IN_PRUEFUNG">
                In Prüfung
              </MenuItem>
              <MenuItem key="FREIGEGEBEN" value="FREIGEGEBEN">
                Freigegeben
              </MenuItem>
              <MenuItem key="ABGELEHNT" value="ABGELEHNT">
                Abgelehnt
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            component="label"
            sx={{ position: "absolute", top: 0, right: 0 }}
            color={"secondary"}
          >
            {t("upload")}
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={handleFileChange}
              multiple={true}
            />
          </Button>
        </>
      )}
    </Box>
  );
}

function useSaveRechnungsanhang() {
  const createRechnungsAnhang = useCreateRechnungsAnhang();
  const queryClient = useQueryClient();

  return useMutation(
    (input: CreateAnhang) => {
      return createRechnungsAnhang(input);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["eingangsrechnungen"]);
      },
    }
  );
}

function EingangsrechnungenResults({
  ...request
}: DataRequestState<EingangsrechnungenFilter>) {
  return <Results request={request} />;
}

function Results({
  request,
}: {
  request: DataRequestState<EingangsrechnungenFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadEingangsrechnungen: DataLoader<
    EingangsrechnungenFilter,
    EingangsrechnungInfo
  > = useCallback(
    async (params) =>
      (await getApi()).rechnungen.sucheRechnungen({ ...params }),
    [getApi]
  );

  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["eingangsrechnungen"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadEingangsrechnungen}
      onRowClick={(row) => navigate(`/eingangsrechnungen/${row.id}`)}
    />
  );
}

function useColumns(): Array<GridColDef<EingangsrechnungInfo>> {
  const { t } = useT("eingangsrechnung");

  return useMemo(
    () => [
      {
        field: "dateiname",
        headerName: t("file"),
        flex: 1,
      },
      {
        field: "belegTyp",
        headerName: t("document-type"),
        flex: 1,
      },
      {
        field: "dateiErstelltAm",
        headerName: t("created_at"),
        renderCell: ({ value }) =>
          value ? new Date(value).toLocaleDateString() : "kein Datum",
        flex: 1,
      },
      {
        field: "belegStatus",
        headerName: t("document-status"),
        renderCell: (params) => {
          const id = params.row.id;
          switch (params.value) {
            case "IN_ERKENNUNG":
              return (
                <Chip
                  icon={<TextIncreaseIcon />}
                  title={"In Erkennung"}
                  label={"in Erkennung"}
                ></Chip>
              );
            case "OFFEN":
              return (
                <Chip
                  icon={<SpellcheckIcon />}
                  title={"Offen"}
                  color={"secondary"}
                  label={"offen"}
                ></Chip>
              );
            case "IN_PRUEFUNG":
              return (
                <Chip
                  icon={<PlaylistAddCheckCircleIcon />}
                  title={"In Prüfung"}
                  color={"warning"}
                  label={"in Prüfung"}
                ></Chip>
              );
            case "FREIGEGEBEN":
              return (
                <Chip
                  icon={<TaskAltIcon />}
                  title={"Freigegeben"}
                  color={"success"}
                  label={"freigegeben"}
                ></Chip>
              );
            case "ABGELEHNT":
              return (
                <Chip
                  icon={<HighlightOffIcon />}
                  title={"Abgelehnt"}
                  color={"error"}
                  label={"abgelehnt"}
                ></Chip>
              );
            case "IN_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"in DATEV"}
                  color={"success"}
                  title={"Verarbeitet durch DATEV"}
                />
              );
            case "VERARBEITUNG_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Verarbeitung DATEV"}
                  color={"warning"}
                  title={"Verarbeitung durch DATEV"}
                />
              );
            case "FEHLER_BEI_UEBERTRAG_DATEV":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Fehler DATEV"}
                  color={"error"}
                  title={"Fehler bei Übertrag nach Datev"}
                />
              );
            case "FEHLER_GOOGLE":
              return (
                <DatevProtokolleButton
                  id={id}
                  label={"Fehler Erkennung"}
                  color={"warning"}
                  title={"Fehler Erkennung Google"}
                />
              );
          }
        },
        flex: 1,
      },
      {
        field: "rechnungsNummer",
        headerName: t("invoice-number"),
        flex: 1,
      },
      {
        field: "baustellenNamen",
        headerName: t("construction-site-name"),
        flex: 1,
      },
      {
        field: "baustellenBezeichnungen",
        headerName: t("construction-site-description"),
        flex: 1,
      },
      {
        field: "lieferantName",
        headerName: t("supplier"),
        flex: 1,
      },
      {
        field: "prueferNamen",
        headerName: t("proufer"),
        flex: 1,
      },
      {
        field: "pruefStatus",
        headerName: t("prouf-status"),
        flex: 1,
      },
      {
        field: "nettoBetrag",
        headerName: t("net-amount"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
      {
        field: "bruttoBetrag",
        headerName: t("gross-amount"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: ({ value }) => {
          return value != null && !isNaN(value)
            ? `${(value as number)
                .toFixed(2)
                .replace(".", ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`
            : "";
        },
      },
    ],
    [t]
  );
}

import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
} from "../data";
import { useT } from "../../i18n";
import DataTable from "../data/DataTable";
import { Externessystem } from "../../api";
import { green, red } from "@mui/material/colors";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useGetApi } from "../../client";
import {
  useDatevAuthUrl,
  useDatevRevoke,
  useGoogleAuthUrl,
  useGoogleRevoke,
} from "../../client/hooks";
import GoogleButton from "../GoogleButton";
import DatevButton from "../DatevButton";
import { useQueryClient } from "react-query";

export type ExterneSystemeFilter = {
  search?: string;
};

export type Page<T> = {
  items: T[];
  page: number;
  limit: number;
  totalPages: number;
  totalElements: number;
};

export type Props = Omit<DataRequest<ExterneSystemeFilter>, "filter"> &
  Partial<Pick<DataRequest<ExterneSystemeFilter>, "filter">>;

export default function ExterneSystemeDataTable({ ...input }: Props) {
  const request = useDataRequest<ExterneSystemeFilter>({
    filter: {},
    ...input,
  });

  return (
    <Stack spacing={2}>
      <ExterneSystemeResults {...request} />
    </Stack>
  );
}

function ExterneSystemeResults({
  ...request
}: DataRequestState<ExterneSystemeFilter>) {
  return <Results request={request} />;
}

function Results({
  request,
}: {
  request: DataRequestState<ExterneSystemeFilter>;
}) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadExterneSysteme: DataLoader<ExterneSystemeFilter, Externessystem> =
    useCallback(
      async (params) =>
        (await getApi()).externesysteme.sucheExternesysteme({ ...params }),
      [getApi]
    );

  return (
    <DataTable
      columns={useColumns(request)}
      request={request}
      queryKey={["externesysteme"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadExterneSysteme}
      size={"large"}
    />
  );
}

function useColumns(
  request: DataRequestState<ExterneSystemeFilter>
): Array<GridColDef<Externessystem>> {
  const { t } = useT("customer");
  const navigate = useNavigate();
  const googlemutate = useGoogleAuthUrl();
  const datevmutate = useDatevAuthUrl();
  const revokeGoogle = useGoogleRevoke();
  const revokeDatev = useDatevRevoke();
  const queryClient = useQueryClient();
  function generateState(): string {
    let state = "";
    const characters = "0123456789";
    const length = 30;
    for (let i = 0; i < length; i++) {
      state += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return state;
  }
  const handleGoogleLogin = async (id: string) => {
    let state = generateState();
    localStorage.setItem("STATE", state);
    let result = await googlemutate(state);
    if (result.authUrl) {
      window.location.href = result.authUrl;
      console.log(`Logging in system with id: ${id}`);
    } else {
      console.error("No auth URL received from Google.");
    }
  };

  const handleDatevLogin = async (id: string) => {
    try {
      let state = generateState();
      localStorage.setItem("DATEV_STATE", state);
      let result = await datevmutate(state);
      if (result.authUrl) {
        window.location.href = result.authUrl;
        console.log(`Logging in system with id: ${id}`);
      } else {
        console.error("No auth URL received from Datev.");
      }
    } catch (error) {
      console.error("Error during Datev login:", error);
    }
  };

  const handleLogout = async (id: string) => {
    let result = await revokeGoogle();
    await queryClient.invalidateQueries("externesysteme");
    alert(result.message);
    console.log(`Logging out system with id: ${id}`);
  };

  const handleDatevLogout = async (id: string) => {
    let result = await revokeDatev();
    await queryClient.invalidateQueries("externesysteme");
    alert(result.message);
    console.log(`Logging out system with id: ${id}`);
  };

  return useMemo(
    () => [
      {
        field: "name",
        headerName: t("System"),
        flex: 2,
      },
      {
        field: "status",
        headerName: t("Status"),
        flex: 2,
        renderCell: (params) => {
          const accessTokenDueDate = params.row.accessTokenDueDate;
          const isExpired = accessTokenDueDate
            ? new Date(accessTokenDueDate) < new Date()
            : false;

          const isConnected = params.value && !isExpired;

          return (
            <Box display="flex" alignItems="center">
              {params.value === "connected" ? (
                <>
                  <CheckCircle sx={{ color: green[500], mr: 1 }} />
                  {t("Connected")}
                </>
              ) : (
                <>
                  <Cancel sx={{ color: red[500], mr: 1 }} />
                  {t("Disconnected")}
                </>
              )}
            </Box>
          );
        },
      },
      {
        field: "accessTokenDueDate",
        headerName: t("Token valid until"),
        flex: 2,
        renderCell: (params) => {
          const accessTokenDueDate = params.row.accessTokenDueDate;
          if (accessTokenDueDate) {
            const formattedDate = new Date(accessTokenDueDate).toLocaleString();
            return formattedDate;
          } else {
            return t("No Expiry Date");
          }
        },
      },
      {
        field: "createdAt",
        headerName: t("New login"),
        flex: 2,
        renderCell: (params) => {
          const { typ, id } = params.row;

          return (
            <>
              {typ === "google" && (
                <GoogleButton onClick={() => handleGoogleLogin(id)}>
                  {t("Sign in with Google")}
                </GoogleButton>
              )}
              {typ === "datev" && (
                <DatevButton
                  loading={false}
                  onClick={() => handleDatevLogin(id)}
                >
                  {t("Anmelden mit DATEV")}
                </DatevButton>
              )}
            </>
          );
        },
      },
      {
        field: "createdBy",
        headerName: t("Log out"),
        flex: 2,
        renderCell: (params) => {
          const { typ, id } = params.row;
          return (
            <>
              {typ === "google" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={params.row.status !== "connected"}
                  onClick={() => handleLogout(params.row.id)}
                >
                  {t("Abmelden")}
                </Button>
              )}
              {typ === "datev" && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={params.row.status !== "connected"}
                  onClick={() => handleDatevLogout(params.row.id)}
                >
                  {t("Abmelden")}
                </Button>
              )}
            </>
          );
        },
      },
    ],
    [t]
  );
}

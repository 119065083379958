import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import Layout from "../../components/Layout";
import {
  Box,
  Button,
  capitalize,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import {
  useCreateFuhrparkeintrag,
  useCreateFuhrparkeintragAnbauteil,
  useCreateMaschinentransport,
  useCreateMaterialtransport,
  useCreateMaterialtransportZwischenBaustellen,
  useCreateTaetigkeitsbericht,
  useUpdateMaschinentransport,
} from "../../client/hooks";
import {
  FuhrparkeintragAnbauteil,
  Maschinentransport,
  Materialtransport,
  MaterialZwischenInfo,
} from "../../api";
import * as Yup from "yup";
import { ExpandContextProvider } from "../../expand";
import DeleteIcon from "@mui/icons-material/Delete";
import FuhrparkeintragFremdPersonalItem from "../../components/taetigkeitsberichte/fuhrparkeintraege/FuhrparkeintragFremdPersonalItem";
import TaetigkeitsberichtFieldsContentExtern from "../../components/taetigkeitsberichte/TaetigkeitsberichtFieldsContentExtern";

interface ExtendedTaetigkeitsberichtDaten {
  lieferantId: string;
  baustelleId: string;
  datum: string;
  fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
}

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugId: string;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: FuhrparkeintragAnbauteil[];
  maschinentransporte: Maschinentransport[];
  materialanfuhr: Materialtransport[];
  materialabtransport: Materialtransport[];
  materialtransport: MaterialZwischenInfo[];
}

const validationSchema = Yup.object().shape({
  baustelleId: yup.string().required(),
  datum: yup.string().required(),
  fuhrparkeintraege: Yup.array()
    .of(
      Yup.object().shape({
        fahrzeugId: yup.mixed().required(),
        fahrzeugNutzungsdauer: yup.number().min(0).required(),
        fahrzeugStundenPreis: yup.number().min(0).required(),
        fahrzeugKosten: yup.number().min(0),
        fuhrparkGesamtKosten: yup.number().min(0),
        bemerkung: yup.string().nullable(),
        fuhrparkeintragAnbauteile: Yup.array()
          .of(
            Yup.object().shape({
              anbauteilId: yup.mixed().required(),
              anbauteilNutzungsdauer: yup.number().min(0).required(),
              anbauteilStundenPreis: yup.number().min(0).required(),
              anbauteilAnzahl: yup.number().min(1).required(),
              anbauteilKosten: yup.number().min(0),
            })
          )
          .min(0, ""),
      })
    )
    .min(0, ""),
});

export default function ErstelleTaetigkeitsberichtExtern() {
  const { t } = useTranslation("taetigkeitsbericht");
  const createTaetigkeitsbericht = useCreateTaetigkeitsbericht();
  const createFuhrparkeintrag = useCreateFuhrparkeintrag();
  const createFuhrparkeintragAnbauteil = useCreateFuhrparkeintragAnbauteil();
  const createMaterialtransport = useCreateMaterialtransport();
  const createMaterialtransportZwischenBaustellen =
    useCreateMaterialtransportZwischenBaustellen();
  const updateMaschinentransport = useUpdateMaschinentransport();
  const createMaschinentransport = useCreateMaschinentransport();
  const navigate = useNavigate();
  enum Buchungstyp {
    AR = "AR",
    ER = "ER",
    PS = "PS",
    FP = "FP",
    MA = "MA",
    AB = "AB",
    DP = "DP",
    TR = "TR",
  }
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleAccordionChange = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  async function saveFuhrparkeintraege(
    values: ExtendedTaetigkeitsberichtDaten & {
      fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
    }
  ) {
    try {
      const taetigkeitsbericht = await createTaetigkeitsbericht({
        baustelleId: values.baustelleId,
        datum: values.datum,
        taetigkeitsberichtTyp: "EXTERN",
        lieferantId: values.lieferantId,
      });

      // Create each fuhrparkeintrag
      const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
        (fuhrparkeintrag) => {
          const fuhrparkeintragData = {
            fahrzeugId: fuhrparkeintrag.fahrzeugId,
            fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
            fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
            fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
            bemerkung: fuhrparkeintrag.bemerkung,
          };
          return createFuhrparkeintrag(
            taetigkeitsbericht.id,
            fuhrparkeintragData
          );
        }
      );

      const fuhrparkeintragResults = await Promise.all(fuhrparkeintragPromises);

      const fuhrparkeintragIds = fuhrparkeintragResults.map(
        (result) => result.id
      );

      const fuhrparkeintragAnbauteilPromises = values.fuhrparkeintraege.flatMap(
        (fuhrparkeintrag, index) => {
          const currentFuhrparkeintragId = fuhrparkeintragIds[index];
          return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
            (fuhrparkeintragAnbauteil) => {
              const fuhrparkeintragAnbauteilData = {
                anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                anbauteilNutzungsdauer:
                  fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                anbauteilStundenPreis:
                  fuhrparkeintragAnbauteil.anbauteilStundenPreis,
              };
              return createFuhrparkeintragAnbauteil(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                fuhrparkeintragAnbauteilData
              );
            }
          );
        }
      );

      await Promise.all(fuhrparkeintragAnbauteilPromises);

      navigate(
        `/fremdleistungen/${taetigkeitsbericht.id}?expandedindexInherited=${expandedIndex}`
      );
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const onSubmit = useCallback(
    async (
      values: ExtendedTaetigkeitsberichtDaten & {
        fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
      },
      formikHelpers: FormikHelpers<
        ExtendedTaetigkeitsberichtDaten & {
          fuhrparkeintraege: ExtendedFuhrparkeintragDaten[];
        }
      >
    ) => {
      const { setSubmitting, setStatus } = formikHelpers;
      try {
        // Create Tätigkeitsbericht first
        const taetigkeitsbericht = await createTaetigkeitsbericht({
          baustelleId: values.baustelleId,
          datum: values.datum,
          taetigkeitsberichtTyp: "EXTERN",
          lieferantId: values.lieferantId,
        });

        // Create each fuhrparkeintrag
        const fuhrparkeintragPromises = values.fuhrparkeintraege.map(
          (fuhrparkeintrag) => {
            const fuhrparkeintragData = {
              fahrzeugId: fuhrparkeintrag.fahrzeugId,
              fahrzeugHerkunft: fuhrparkeintrag.fahrzeugHerkunft,
              fahrzeugStundenPreis: fuhrparkeintrag.fahrzeugStundenPreis,
              fahrzeugNutzungsdauer: fuhrparkeintrag.fahrzeugNutzungsdauer,
              bemerkung: fuhrparkeintrag.bemerkung,
            };
            return createFuhrparkeintrag(
              taetigkeitsbericht.id,
              fuhrparkeintragData
            );
          }
        );

        const fuhrparkeintragResults = await Promise.all(
          fuhrparkeintragPromises
        );

        const fuhrparkeintragIds = fuhrparkeintragResults.map(
          (result) => result.id
        );

        const fuhrparkeintragAnbauteilPromises =
          values.fuhrparkeintraege.flatMap((fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.fuhrparkeintragAnbauteile.map(
              (fuhrparkeintragAnbauteil) => {
                const fuhrparkeintragAnbauteilData = {
                  anbauteilId: fuhrparkeintragAnbauteil.anbauteilId,
                  anbauteilNutzungsdauer:
                    fuhrparkeintragAnbauteil.anbauteilNutzungsdauer,
                  anbauteilAnzahl: fuhrparkeintragAnbauteil.anbauteilAnzahl,
                  anbauteilStundenPreis:
                    fuhrparkeintragAnbauteil.anbauteilStundenPreis,
                };
                return createFuhrparkeintragAnbauteil(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  fuhrparkeintragAnbauteilData
                );
              }
            );
          });

        await Promise.all(fuhrparkeintragAnbauteilPromises);

        const maschinentransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.maschinentransporte.map(
              (maschinentransport) => {
                const maschinentransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  transportiertesFahrzeugId:
                    maschinentransport.transportiertesFahrzeugId,
                  abholortBaustellenId: maschinentransport.abholortBaustellenId,
                  abstellortBaustellenId:
                    maschinentransport.abstellortBaustellenId,
                  fuhrTyp: Buchungstyp.TR,
                  lieferscheinNummer: maschinentransport.lieferscheinNummer,
                  bemerkung: maschinentransport.bemerkung,
                };
                if (maschinentransport.id) {
                  return updateMaschinentransport(
                    taetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    maschinentransport.id,
                    maschinentransportData
                  );
                } else {
                  return createMaschinentransport(
                    taetigkeitsbericht.id,
                    currentFuhrparkeintragId,
                    maschinentransportData
                  );
                }
              }
            );
          }
        );

        await Promise.all(maschinentransportPromises);

        const materialanfuhrPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialanfuhr.map((materialtransport) => {
              const materialtransportData = {
                baustelleId: taetigkeitsbericht.baustelleId,
                fuhrTyp: Buchungstyp.MA,
                buchungsTyp: Buchungstyp.MA,
                lieferscheinNummer: materialtransport.lieferscheinNummer,
                materialId: materialtransport.materialId,
                quelleAbladestelleId: materialtransport.quelleAbladestelleId,
                bemerkung: materialtransport.bemerkung,
                einheit: materialtransport.einheit,
                anzahlFuhren: materialtransport.anzahlFuhren,
                menge: materialtransport.menge,
                kostenProEinheit: materialtransport.kostenProEinheit,
                meLadeVolumenFahrzeug: materialtransport.meLadeVolumenFahrzeug,
                buchungsArt: materialtransport.buchungsArt,
              };
              return createMaterialtransport(
                taetigkeitsbericht.id,
                currentFuhrparkeintragId,
                materialtransportData
              );
            });
          }
        );

        await Promise.all(materialanfuhrPromises);

        const materialabtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialabtransport.map(
              (materialtransport) => {
                const materialtransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  fuhrTyp: materialtransport.fuhrTyp,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  materialId: materialtransport.materialId,
                  quelleAbladestelleId: materialtransport.quelleAbladestelleId,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  menge: materialtransport.menge,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                  bsNr: materialtransport.bsNr,
                  entsorgerId: materialtransport.entsorgerId,
                  erzeugerId: materialtransport.erzeugerId,
                };
                return createMaterialtransport(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialtransportData
                );
              }
            );
          }
        );

        await Promise.all(materialabtransportPromises);

        const materialtransportPromises = values.fuhrparkeintraege.flatMap(
          (fuhrparkeintrag, index) => {
            const currentFuhrparkeintragId = fuhrparkeintragIds[index];
            return fuhrparkeintrag.materialtransport.map(
              (materialtransport) => {
                const materialtransportData = {
                  baustelleId: taetigkeitsbericht.baustelleId,
                  buchungsTyp: materialtransport.buchungsTyp,
                  lieferscheinNummer: materialtransport.lieferscheinNummer,
                  materialId: materialtransport.materialId,
                  zielBaustelleId: materialtransport.zielBaustelleId,
                  bemerkung: materialtransport.bemerkung,
                  einheit: materialtransport.einheit,
                  anzahlFuhren: materialtransport.anzahlFuhren,
                  menge: materialtransport.menge,
                  kostenProEinheit: materialtransport.kostenProEinheit,
                  meLadeVolumenFahrzeug:
                    materialtransport.meLadeVolumenFahrzeug,
                  buchungsArt: materialtransport.buchungsArt,
                };
                return createMaterialtransportZwischenBaustellen(
                  taetigkeitsbericht.id,
                  currentFuhrparkeintragId,
                  materialtransportData
                );
              }
            );
          }
        );

        await Promise.all(materialtransportPromises);

        setStatus(undefined);

        navigate(
          `/fremdleistungen/${taetigkeitsbericht.id}?expandedindexInherited=${expandedIndex}`
        );
      } catch (error: any) {
        setStatus(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [
      navigate,
      createTaetigkeitsbericht,
      createFuhrparkeintrag,
      createFuhrparkeintragAnbauteil,
      t,
    ]
  );

  return (
    <Layout
      title={capitalize(t("create-activity-report-external"))}
      back={"/fremdleistungen"}
    >
      <Paper
        elevation={1}
        sx={{ marginTop: 2, padding: 3, marginLeft: 2, marginRight: 2 }}
      >
        <Formik
          initialValues={{
            lieferantId: "",
            baustelleId: "",
            datum: "",
            fuhrparkeintraege: [] as ExtendedFuhrparkeintragDaten[],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValidating, isValid, dirty, values }) => {
            return (
              <>
                <Typography variant="h5">
                  {capitalize(t("new-activity-report-external"))}
                </Typography>
                <DialogContent>
                  <Form id="neuer-taetigkeitsbericht">
                    <TaetigkeitsberichtFieldsContentExtern isNew={true} />
                    <FieldArray name="fuhrparkeintraege">
                      {({ push, remove }) => (
                        <>
                          {values.fuhrparkeintraege.map(
                            (fuhrparkeintrag, index) => (
                              <Box key={index} sx={{ marginTop: 2 }}>
                                <Box
                                  display={"grid"}
                                  sx={{
                                    gridTemplateColumns: ["1fr", "10fr .1fr"],
                                    gap: 2,
                                    marginTop: 2,
                                  }}
                                >
                                  <ExpandContextProvider
                                    id={`fuhrparkeintrag-${index}`}
                                  >
                                    <FuhrparkeintragFremdPersonalItem
                                      isNew={!fuhrparkeintrag.id}
                                      fuhrparkeintrag={fuhrparkeintrag}
                                      index={index}
                                      expanded={expandedIndex === index}
                                      handleChange={handleAccordionChange}
                                      taetigkeitsberichtDatum={values.datum}
                                      dauerVorhanden={0}
                                      isValid={isValid}
                                      isValidating={isValidating}
                                    />

                                    <IconButton
                                      onClick={() => remove(index)}
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ExpandContextProvider>
                                </Box>
                              </Box>
                            )
                          )}
                          <Box sx={{ marginTop: "2em", marginBottom: "2em" }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                var werte = 0;

                                push({
                                  fahrzeugId: "",
                                  fahrzeugHerkunft: "",
                                  fahrzeugStundenPreis: 0,
                                  fahrzeugNutzungsdauer: 0,
                                  bemerkung: "",
                                  fuhrparkeintragAnbauteile: [
                                    {
                                      anbauteilId: "",
                                      anbauteilNutzungsdauer: 0,
                                      anbauteilAnzahl: 1,
                                      anbauteilStundenPreis: 0,
                                      anbauteilKosten: 0,
                                    },
                                  ],
                                  maschinentransporte: [],
                                  materialanfuhr: [],
                                  materialabtransport: [],
                                  materialtransport: [],
                                });

                                values.fuhrparkeintraege.map(() => {
                                  werte++;
                                });

                                if (werte > 0) {
                                  saveFuhrparkeintraege(values);
                                }
                              }}
                              disabled={isValidating || !isValid}
                            >
                              {capitalize(t("add-carpool"))}
                            </Button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <SubmitButton
                    form="neuer-taetigkeitsbericht"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    disabled={isValidating || !isValid || !dirty}
                  >
                    {capitalize(t("save"))}
                  </SubmitButton>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </Paper>
    </Layout>
  );
}

import { Box, Button, capitalize, IconButton, Stack } from "@mui/material";
import { FastField } from "formik";
import { TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React from "react";
import { useDeleteMaschinentransport } from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Maschinentransport } from "../../../../api";
import FahrzeugSelect from "../../../fuhrpark/fahrzeug/FahrzeugSelect";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";

export default function MaschinentransportFieldsContent({
  index,
  maschinenTransportIndex,
  push,
  remove,
  newestIndex,
  maschinentransport,
}: {
  index: number;
  maschinenTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  maschinentransport: Maschinentransport;
}) {
  const { t } = useT("taetigkeitsbericht");

  const deleteMaschinentransport = useDeleteMaschinentransport();

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {capitalize(t("vehicle-transport"))}
      </Stack>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "5fr 4fr 4fr 3fr 1fr 1fr"],
          gap: 2,
        }}
      >
        <FastField
          component={FahrzeugSelect}
          name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.transportiertesFahrzeugId`}
          label={capitalize(t("vehicle-transport-vehicle"))}
          required
          fahrzeugHerkunft={"EIGEN_GERAET"}
        />
        <FastField
          component={BaustellenSelect}
          name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.abholortBaustellenId`}
          label={capitalize(t("vehicle-transport-from"))}
          required
        />
        <FastField
          component={BaustellenSelect}
          name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.abstellortBaustellenId`}
          label={capitalize(t("vehicle-transport-to"))}
          required
        />
        <FastField
          component={TextField}
          label={capitalize(t("vehicle-transport-lfs"))}
          name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.lieferscheinNummer`}
          required
        />

        {maschinenTransportIndex === newestIndex ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              push({
                transportiertesFahrzeugId: "",
                abholortBaustellenId: "",
                abstellortBaustellenId: "",
                lieferscheinNummer: "",
                bemerkung: "",
              });
            }}
          >
            <AddCircleIcon />
          </Button>
        ) : (
          <></>
        )}
        <IconButton
          onClick={() => {
            const maschinentransportId = maschinentransport.id;
            if (maschinentransportId) {
              deleteMaschinentransport(
                maschinentransport.taetigkeitsberichtId,
                maschinentransport.fuhrparkeintragId,
                maschinentransportId
              ).then(() => {
                remove(maschinenTransportIndex);
              });
            } else {
              remove(maschinenTransportIndex);
            }
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          marginTop: 2,
        }}
      >
        <FastField
          component={TextField}
          label={capitalize(t("vehicle-transport-remark"))}
          name={`fuhrparkeintraege.${index}.maschinentransporte.${maschinenTransportIndex}.bemerkung`}
          required
        />
      </Box>
    </Stack>
  );
}

// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type MaterialtransportPage } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheMaterialtransporteQuery = {
  search?: string;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheMaterialtransporte(): (
  taetigkeitsberichtId: string,
  fuhrparkeintragId: string,
  query: SucheMaterialtransporteQuery
) => Promise<MaterialtransportPage> {
  const getAPI = useGetApi();
  return useCallback(
    async (taetigkeitsberichtId, fuhrparkeintragId, query = {}) =>
      (await getAPI()).materialtransporte.sucheMaterialtransporte({
        taetigkeitsberichtId,
        fuhrparkeintragId,
        ...query,
      }),
    [getAPI]
  );
}

// This file is generated, do not edit!
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { type BaustellenListe } from "../../api";
import { useGetApi } from "../client";
import { useCallback } from "react";

export type SucheBaustellenQuery = {
  search?: string;
  kundenId?: string;
  archivierte?: boolean;
  page?: number;
  limit?: number;
  sort?: string;
};

export function useSucheBaustellen(): (
  query: SucheBaustellenQuery
) => Promise<BaustellenListe> {
  const getAPI = useGetApi();
  return useCallback(
    async (query = {}) =>
      (await getAPI()).baustellen.sucheBaustellen({ ...query }),
    [getAPI]
  );
}

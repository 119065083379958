import {
  Box,
  Button,
  capitalize,
  IconButton,
  MenuItem,
  Stack,
} from "@mui/material";
import { FastField, useField, useFormikContext } from "formik";
import { Select, TextField } from "formik-mui";
import { useT } from "../../../../i18n";
import React, { useEffect, useState } from "react";
import {
  useDeleteMaterialtransport,
  useGetFahrzeug,
} from "../../../../client/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Materialtransport } from "../../../../api";
import AbladestellenSelect from "../../../abladestellen/AbladestellenSelect";
import MaterialienSelect from "../../../material/MaterialienSelect";
import Buchungsart from "../../../Buchungsart";
import BaustellenSelect from "../../../baustellen/BaustellenSelect";

export default function MaterialanfuhrFieldsContent({
  index,
  materialTransportIndex,
  push,
  remove,
  newestIndex,
  materialtransport,
  fahrzeugId,
}: {
  index: number;
  materialTransportIndex: number;
  push: (value: any) => void;
  remove: (value: any) => void;
  newestIndex: number;
  materialtransport: Materialtransport;
  fahrzeugId: string;
}) {
  const { t } = useT("taetigkeitsbericht");
  const deleteMaterialtransport = useDeleteMaterialtransport();
  const { setFieldValue } = useFormikContext();
  const [isKosten, setIsKosten] = useState(
    materialtransport.buchungsArt === "Kosten"
  );
  const getfahrzeug = useGetFahrzeug();
  const [currentfahrzeugLadeMenge, setCurrentFahrzeugLadeMenge] =
    useState<number>(0);
  const [abladestelleId, setAbladestelleId] = useState<String | null>(
    materialtransport?.quelleAbladestelleId ?? null
  );
  const [mengeField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.menge`
  );
  const [preisField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.kostenProEinheit`
  );
  const [fuhrenField] = useField(
    `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.anzahlFuhren`
  );
  useEffect(() => {
    const fetchData = async () => {
      if (fahrzeugId) {
        const data = await getfahrzeug(fahrzeugId);
        setCurrentFahrzeugLadeMenge(data.ladeMenge ?? 0);
      }
    };
    fetchData();
  }, [fahrzeugId]);
  useEffect(() => {
    setFieldValue(
      `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.mengeGesamt`,
      mengeField.value * fuhrenField.value
    );
    setFieldValue(
      `fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.preisGesamt`,
      (mengeField.value * fuhrenField.value * preisField.value).toFixed(2)
    );
  }, [mengeField.value, fuhrenField.value, preisField.value]);
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ marginTop: 5, marginBottom: 2 }}
      >
        {"Materialanfuhr"}
      </Stack>

      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "5fr 4fr 4fr 3fr 1fr 1fr"],
          gap: 2,
        }}
      >
        {materialtransport.zielBaustelleId ? (
          <FastField
            component={BaustellenSelect}
            name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.zielBaustelleId`}
            label={"Abladestelle"}
            required
          />
        ) : (
          <FastField
            component={AbladestellenSelect}
            name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.quelleAbladestelleId`}
            label={"Abladestelle"}
            required
            setAbladestelleId={setAbladestelleId}
          />
        )}
        <FastField
          component={TextField}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.meLadeVolumenFahrzeug`}
          label={"ME (Ladevolumen Fahrzeug)"}
          required
          type={"number"}
          inputProps={{ step: 1 }}
        />
        {abladestelleId ? (
          <FastField
            component={MaterialienSelect}
            name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.materialId`}
            label={"Material"}
            abladestelleId={abladestelleId}
            required
          />
        ) : (
          <FastField
            component={Select}
            name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.materialId`}
            label={"Material"}
            disabled={true}
          />
        )}
        <FastField
          component={TextField}
          label={"Preis"}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.kostenProEinheit`}
          required
          type={"number"}
          inputProps={{ step: 0.01 }}
        />

        {materialTransportIndex === newestIndex ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              push({
                quelleAbladestelleId: "",
                meLadeVolumenFahrzeug: currentfahrzeugLadeMenge ?? 0,
                anzahlFuhren: "",
                lieferscheinNummer: "",
                bemerkung: "",
                buchungsArt: "Kosten",
              });
            }}
          >
            <AddCircleIcon />
          </Button>
        ) : (
          <></>
        )}
        <IconButton
          onClick={() => {
            const materialransportId = materialtransport.id;
            if (materialransportId) {
              deleteMaterialtransport(
                materialtransport.taetigkeitsberichtId,
                materialtransport.fuhrparkeintragId,
                materialransportId
              ).then(() => {
                remove(materialTransportIndex);
              });
            } else {
              remove(materialTransportIndex);
            }
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1.5fr 1fr 2fr 2fr 2fr 1fr"],
          mt: 2,
          gap: 2,
        }}
      >
        <FastField
          component={TextField}
          label={"Anzahl Fuhren"}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.anzahlFuhren`}
          required
          type={"number"}
          inputProps={{ step: 1 }}
        />
        <FastField
          component={Select}
          label={"Einheit"}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.einheit`}
          required
        >
          <MenuItem value={"CB"}>CBM</MenuItem>
          <MenuItem value={"TO"}>TO</MenuItem>
          <MenuItem value={"ST"}>STÜCK</MenuItem>
          <MenuItem value={"PA"}>PAUSCHAL</MenuItem>
        </FastField>
        <FastField
          component={TextField}
          label={"Menge"}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.menge`}
          required
          type={"number"}
          inputProps={{ step: 1 }}
        />
        <FastField
          component={TextField}
          label={"Gesamtmenge"}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.mengeGesamt`}
          type={"number"}
          inputProps={{ step: 0.01, readOnly: true }}
          InputLabelProps={{ shrink: true }}
          disabled={true}
        />
        <FastField
          component={TextField}
          label={"Gesamtpreis"}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.preisGesamt`}
          type={"number"}
          inputProps={{ step: 0.01, readOnly: true }}
          InputLabelProps={{ shrink: true }}
          disabled={true}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "3fr 5fr 1fr"],
          mt: 2,
          gap: 2,
        }}
      >
        <FastField
          component={TextField}
          label={"LFS-Nr"}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.lieferscheinNummer`}
        />
        <FastField
          component={TextField}
          label={capitalize(t("vehicle-transport-remark"))}
          name={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.bemerkung`}
        />
      </Box>
      <Box
        display={"grid"}
        sx={{
          gridTemplateColumns: ["1fr", "1fr 1fr"],
          gap: 2,
          ml: 1,
          mt: 2,
          mb: 2,
        }}
      >
        <Buchungsart
          isKosten={isKosten}
          fieldName={`fuhrparkeintraege.${index}.materialanfuhr.${materialTransportIndex}.buchungsArt`}
        />
      </Box>
    </Stack>
  );
}

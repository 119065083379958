import {
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataLoader,
  DataRequest,
  DataRequestState,
  useDataRequest,
  useDebouncedFilter,
} from "../data";
import { useT } from "../../i18n";
import SearchField from "../SearchField";
import { useGetApi } from "../../client";
import DataTable from "../data/DataTable";
import { BaustellenInfo } from "../../api";

export type BaustellenFilter = {
  search?: string;
  kundenId?: string;
  archivierte?: boolean;
};

export type Props = Omit<DataRequest<BaustellenFilter>, "filter"> &
  Partial<Pick<DataRequest<BaustellenFilter>, "filter">>;

export default function BaustellenDataTable({ ...input }: Props) {
  const request = useDataRequest<BaustellenFilter>({ filter: {}, ...input });

  return (
    <Stack spacing={2}>
      <FilterComp {...request} />
      <BaustellenResults {...request} />
    </Stack>
  );
}

function FilterComp(request: DataRequestState<BaustellenFilter>) {
  const [{ search }, setField] = useDebouncedFilter(request);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        width: "100%",
      }}
    >
      {isMobile ? (
        <Stack direction="row">
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginLeft: 2 }}
            onClick={() => {
              navigate("/neu-baustelle");
            }}
          >
            Baustelle anlegen
          </Button>
        </Stack>
      ) : (
        <>
          <SearchField
            value={search ?? ""}
            onChange={(s) => setField("search", s)}
          />
          <Checkbox
            onChange={(s) => setField("archivierte", s.target.checked)}
          />
          <Typography>Archivierte anzeigen?</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              navigate("/neu-baustelle");
            }}
          >
            Baustelle anlegen
          </Button>
        </>
      )}
    </Box>
  );
}

function BaustellenResults({ ...request }: DataRequestState<BaustellenFilter>) {
  return <Results request={request} />;
}

function Results({ request }: { request: DataRequestState<BaustellenFilter> }) {
  const navigate = useNavigate();
  const getApi = useGetApi();
  const loadBaustellen: DataLoader<BaustellenFilter, BaustellenInfo> =
    useCallback(
      async (params) =>
        (await getApi()).baustellen.sucheBaustellen({ ...params }),
      [getApi]
    );

  return (
    <DataTable
      columns={useColumns()}
      request={request}
      queryKey={["baustellen"]} //TODO: Richtigen QueryKey verwenden
      loadData={loadBaustellen}
      onRowClick={(row) => {
        navigate("/baustellen/" + row.id);
      }}
    />
  );
}

function useColumns(): Array<GridColDef<BaustellenInfo>> {
  const { t, capitalize } = useT("customer");

  return useMemo(
    () => [
      {
        field: "nummer",
        headerName: t("number"),
      },
      {
        field: "name",
        headerName: t("name"),
        flex: 1,
      },
      {
        field: "kundenName",
        headerName: t("customer-name"),
        flex: 1,
      },
      {
        field: "strasse",
        headerName: t("street"),
        flex: 1,
      },
      {
        field: "plz",
        headerName: t("plz"),
        flex: 1,
      },
      {
        field: "ort",
        headerName: t("city"),
        flex: 1,
      },
      {
        field: "auftragsSumme",
        headerName: t("order-total"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: ({ value }) =>
          `${(value as number)
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`,
      },
      {
        field: "saldo",
        headerName: t("balance"),
        flex: 1,
        align: "right",
        headerAlign: "right",
        renderCell: ({ value }) =>
          `${(value as number)
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`,
      },
    ],
    [t]
  );
}

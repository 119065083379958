import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  capitalize,
  Stack,
} from "@mui/material";
import {
  FuhrparkeintragAnbauteil,
  Maschinentransport,
  Materialtransport,
  MaterialZwischenInfo,
} from "../../../api";
import { useTranslation } from "react-i18next";
import FuhrparkeintragFieldsContent from "./FuhrparkeintragFieldsContent";

interface ExtendedFuhrparkeintragDaten {
  id: string;
  taetigkeitsberichtId: string;
  fahrzeugId: string;
  fahrzeugHerkunft: string;
  fahrzeugStundenPreis: number;
  fahrzeugKosten: number;
  fuhrparkGesamtKosten: number;
  fahrzeugNutzungsdauer: number;
  bemerkung?: string;
  anbauteilGesamtKosten: number;
  fuhrparkeintragAnbauteile: FuhrparkeintragAnbauteil[];
  maschinentransporte: Maschinentransport[];
  materialanfuhr: Materialtransport[];
  materialabtransport: Materialtransport[];
  materialtransport: MaterialZwischenInfo[];
}

export default function FuhrparkeintragEigenPersonalItem({
  isNew,
  fuhrparkeintrag,
  index,
  expanded,
  handleChange,
  taetigkeitsberichtDatum,
  dauerVorhanden,
  isValidating,
  isValid,
}: {
  isNew: boolean;
  fuhrparkeintrag: ExtendedFuhrparkeintragDaten;
  index: number;
  expanded: boolean;
  handleChange: (index: number) => void;
  taetigkeitsberichtDatum?: string;
  dauerVorhanden: number;
  isValidating: boolean;
  isValid: boolean;
}) {
  const { t } = useTranslation("taetigkeitsbericht");

  return (
    <Accordion
      key={fuhrparkeintrag.id}
      expanded={expanded}
      onChange={() => handleChange(index)}
      sx={{ width: "100%", padding: 2 }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {capitalize(t("carpool-entry"))}
          </Stack>
        </Stack>
      </AccordionSummary>
      {expanded && (
        <AccordionDetails sx={{ p: 0 }}>
          <FuhrparkeintragFieldsContent
            isNew={isNew}
            index={index}
            fahrzeugHerkunft={"EIGEN_GERAET"}
            taetigkeitsberichtDatum={taetigkeitsberichtDatum}
            dauerVorhanden={dauerVorhanden}
            isValidating={isValidating}
            isValid={isValid}
            isFremdleistung={false}
            fuhrparkeintrag={fuhrparkeintrag}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
}
